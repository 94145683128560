import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;


export interface TenantApiKeyUpdateRequest {
	id: number;
	apiKey: string;
}

export const apiPortalSlice = createApi({
	reducerPath: 'api-portal',
	baseQuery: fetchBaseQuery({
		baseUrl: REACT_APP_API_URL,
	}),
	endpoints: (builder) => ({
		updateTenantApiKey: builder.mutation<any, TenantApiKeyUpdateRequest>({
			query: (data) => ({
				url: `/tenant/updateapikey`,
				method: 'PUT',
				body: data,
			}),

		}),
	}),
});

export const {
	useUpdateTenantApiKeyMutation
} = apiPortalSlice;
