import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import {
	Panel,
	Label,
	Stack,
	PrimaryButton,
	TextField,
	Persona,
	PersonaSize,
	DefaultButton,
	Toggle,
	CommandBarButton,
	IColumn,
	PanelType,
	FontWeights,
	Dropdown,
	ScrollablePane,
	ScrollbarVisibility,
	mergeStyles,
	IPanelStyles,
	MessageBar,
	MessageBarType,
	Link,
	Image,
} from '@fluentui/react';
import { IAdminStatusUpdate, IPortalPartner, ITenant, ITenantPartner, ITenantUser, TenantListPanels } from '../../data-structures/interfaces';
import { dropdownStyles, labelHeadingStyle, labelInfoStyle, panelActionButtonStyle } from '../../styles/PanelStyle';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { updateUser, assignTenants, removeTenants, updateAdminStatus, addTenant, getTenantSites, getSiteLibraries } from '../../redux/modules/user';
import { v4 as uuidv4 } from 'uuid';
import ViewListPanel from './ViewListPanel';
import OrderedFieldsPanel from './OrderedFieldsPanel';
import { LandingPage, PanelReturnType, SubscriptionStatusEnum, TenantListSections } from '../../data-structures/enums';
import ListToggleSection from '../ListToggleSection';
import ManageSitesPanel from './ManageSitesPanel';
import DataSourcePanel from './DataSourcePanel';
import ApiKeyItem from '../../_v2/components/FormItems/ApiKeyItem';
import { lang } from 'moment';
import { EmailMappingPanel } from './EmailMappingPanel';
import { addTenantAPI, assignTenantsAPI, clearTenantUserEmailMappings, getPartners, getTenantSubscriptions, removeTenantsAPI } from '../../utilities/helpers/ApiHelper';
import ColorPickerPanel from './ColorPickerPanel';

const initList: TenantListPanels[] = [
	{
		isOpen: false,
		type: TenantListSections.AdvancedSearch,
	},
	{
		isOpen: false,
		type: TenantListSections.MetaData,
	},
	{
		isOpen: false,
		type: TenantListSections.Sites,
	},
	{
		isOpen: false,
		type: TenantListSections.Teams,
	},
];

const sectionSeparator: React.CSSProperties = {
	borderTop: '#ececec 1px solid',
	paddingTop: 10,
};

export const panelStyle: Partial<IPanelStyles> = {
	content: { paddingLeft: 25, paddingRight: 5 },
};

export const scrollStyle = mergeStyles({
	position: 'relative',
	height: 'calc(100vh - 140px)',
	marginTop: 5,
});

const ocrLanguageDropDownOptions = [
	{ key: '', text: '' },
	{ key: 'bel', text: 'Belgian' },
	{ key: 'chi_sim', text: 'Chinese' },
	{ key: 'nld', text: 'Dutch' },
	{ key: 'eng', text: 'English' },
	{ key: 'fra', text: 'French' },
	{ key: 'deu', text: 'German' },
	{ key: 'ita', text: 'Italian' },
	{ key: 'jpn', text: 'Japanese' },
	{ key: 'pol', text: 'Polish' },
	{ key: 'rus', text: 'Russian' },
	{ key: 'spa', text: 'Spanish' },
];

const newTemplateTypesDropDownOptions = [
	{ key: 'bel', text: lang },
	{ key: 'chi_sim', text: 'Chinese' },
	{ key: 'nld', text: 'Dutch' },
	{ key: 'eng', text: 'English' },
	{ key: 'fra', text: 'French' },
	{ key: 'deu', text: 'German' },
	{ key: 'ita', text: 'Italian' },
	{ key: 'jpn', text: 'Japanese' },
	{ key: 'pol', text: 'Polish' },
	{ key: 'rus', text: 'Russian' },
	{ key: 'spa', text: 'Spanish' },
];

const mapStateToProps = (state: RootState) => {
	return {
		partnerId: state.user.id,
		isPartner: state.user.isPartner,
		isGlobalAdmin: state.user.isGlobalAdmin,
		isTenantAdmin: state.user.isTenantAdmin,
		users: state.user.users,
		isLoading: state.user.isUserLoading,
	};
};

const mapDispatchToProps = {
	updateUser,
	assignTenants,
	removeTenants,
	updateAdminStatus,
	addTenant,
	getTenantSites,
	getSiteLibraries
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface EditTenantPanelProps extends PropsFromRedux {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setShowGlobalDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
	tenant: ITenant;
	tenantUsers: ITenantUser[];
	updateTenant: (newTenant: ITenant, oldItem: ITenant) => void;
	validateName: (value: string) => string;
	closeOnAddSuccess: () => void;
	browseToSubscription: (tenant: ITenant) => void;
}

const EditTenantPanel: React.FC<EditTenantPanelProps> = ({
	isOpen,
	setIsOpen,
	tenant,
	tenantUsers,
	validateName,
	updateTenant,
	setShowDeleteDialog,
	setShowGlobalDeleteDialog,
	isPartner,
	isGlobalAdmin,
	isTenantAdmin,
	users,
	assignTenants,
	removeTenants,
	updateAdminStatus,
	closeOnAddSuccess,
	isLoading,
	addTenant,
	browseToSubscription,
	getTenantSites,
	getSiteLibraries
}: EditTenantPanelProps) => {
	const languageStrings = React.useContext(LanguageContext);
	const [originalItem, setOriginalItem] = React.useState<ITenant>(null);
	const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
	const [newTenant, setNewTenant] = React.useState<ITenant>({ ...tenant });
	const [isPartnerViewListOpen, setIsPartnerViewListOpen] = React.useState(false);
	const [isAdminViewListOpen, setIsAdminViewListOpen] = React.useState(false);
	const [newPartner, setNewPartner] = React.useState<ITenantPartner[]>([tenant?.partner]);
	const [newTenantAdmins, setNewTenantAdmins] = React.useState<ITenantUser[]>(tenantUsers.filter((u) => u.isTenantAdmin));
	const [returnType, setReturnType] = React.useState<PanelReturnType>(null);
	const [tenantListPanels, setTenantListPanels] = React.useState<TenantListPanels[]>(initList);
	const [isManageSitesOpen, setIsManageSitesOpen] = React.useState<boolean>(false);
	const [isDataSourcePanelOpen, setIsDataSourcePanelOpen] = React.useState<boolean>(false);
	const [isColorPickerPanelOpen, setIsColorPickerPanelOpen] = React.useState<boolean>(false);
	const [isSelectPiiInfoTypesPanelOpen, setIsSelectPiiInfoTypesPanelOpen] = React.useState<boolean>(false);
	const [isEmailMappingPanelOpen, setIsEmailMappingPanelOpen] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string>("");
	const [partners, setPartners] = React.useState<IPortalPartner[]>([]);
	const [subscriptionCount, setSubscriptionCount] = React.useState(0);
	const [tenantSiteLibrariesCount, setTenantSiteLibrariesCount] = React.useState<number>(0);
	const [tenantSiteLibrariesLoaded, setTenantSiteLibrariesLoaded] = React.useState<boolean>(false);

	const fileUploadInput = React.useRef<HTMLInputElement>(null);

	const [newTemplateTypesDropDownOptions, setNewTemplateTypesDropDownOptions] = React.useState([
		{ key: 'folder', text: languageStrings.Folder },
		{ key: 'docset', text: languageStrings.DocumentSet },
		{ key: 'file', text: languageStrings.File },
	]);

	React.useEffect(() => {
		(async () => {
			setPartners(await getPartners());
		})();
	}, []);

	React.useEffect(() => {
		(async () => {
			if (tenant) {
				if (!tenant.sites) {
					getTenantSites(tenant.id);
				} else {
					if (tenant && tenant.sites && tenant.sites.length > 0) {
						if (!tenantSiteLibrariesLoaded) {
							await Promise.all(tenant.sites.map(async (site) => {
								getSiteLibraries(site.id, site.tenantId);
							}));

							setTenantSiteLibrariesLoaded(true)
							
						} else {
							let count: number = 0;

							tenant.sites.forEach((site) => {
								if (site.documentLibraries && site.documentLibraries.length > 0) {
									count += site.documentLibraries.length;
								}
							});

							setTenantSiteLibrariesCount(count);
						}
					}
				}
			}
		})()

	}, [tenant?.sites, tenantSiteLibrariesLoaded]);

	React.useEffect(() => {
		if (isOpen == true) {
			(async () => {
				const originalTenant: ITenant = {
					...tenant,
					showAllAdvancedSearchFields: checkIfAllFields(tenant, TenantListSections.AdvancedSearch),
					showAllMetaDataFields: checkIfAllFields(tenant, TenantListSections.MetaData),
					showAllSites: checkIfAllFields(tenant, TenantListSections.Sites),
					showAllTeams: checkIfAllFields(tenant, TenantListSections.Teams),
					key: uuidv4(),
				};
				setOriginalItem(originalTenant);
				setNewTenant({
					...tenant,
					showAllAdvancedSearchFields: checkIfAllFields(tenant, TenantListSections.AdvancedSearch),
					showAllMetaDataFields: checkIfAllFields(tenant, TenantListSections.MetaData),
					showAllSites: checkIfAllFields(tenant, TenantListSections.Sites),
					showAllTeams: checkIfAllFields(tenant, TenantListSections.Teams),
				});

				const subscriptionsRes = await getTenantSubscriptions(originalTenant.id, languageStrings);
				setSubscriptionCount((subscriptionsRes.filter(x => x.status !== SubscriptionStatusEnum.Canceled).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()).length));
			})();
		}
		setIsSaveDisabled(true);
	}, [isOpen]);

	React.useEffect(() => {
		if (tenant != null && !(tenant.id == null) && originalItem != null) {
			isDirty();
		}
	}, [newTenant]);
	//newTenant.enableTeams, newTenant.enableSharePoint, newTenant.enableOneDrive, newTenant.saveOnSend, newTenant.allowedAdvancedSearchFields, newTenant.showAllAdvancedSearchFields
	React.useEffect(() => {
		setNewTenant({
			...tenant,
			showAllAdvancedSearchFields: checkIfAllFields(tenant, TenantListSections.AdvancedSearch),
			showAllMetaDataFields: checkIfAllFields(tenant, TenantListSections.MetaData),
			showAllSites: checkIfAllFields(tenant, TenantListSections.Sites),
			showAllTeams: checkIfAllFields(tenant, TenantListSections.Teams),
		});
	}, [tenant]);

	React.useEffect(() => {
		setNewTenantAdmins(tenantUsers.filter((u) => u.isTenantAdmin));
	}, [tenantUsers]);

	React.useEffect(() => {
		(async () => {
			switch (returnType) {
				case PanelReturnType.Add:
					try {
						const reduxPartner: ITenantPartner = {
							id: newPartner[0].id,
							name: newPartner[0].name
						}

						await assignTenantsAPI(newPartner[0].id, [tenant.id]);
						assignTenants([tenant], reduxPartner);
					} catch (error) {

					}

					setReturnType(null);
					break;
				case PanelReturnType.Remove:
					try {
						await removeTenantsAPI(newPartner[0].id, [tenant.id]);
						removeTenants([tenant], newPartner[0]);
					} catch (error) {

					}

					setReturnType(null);
					break;
				case PanelReturnType.CreateTenant:
					setNewTenant({
						...newTenant,
						partner: newPartner[0],
					});
					setReturnType(null);
					break;
				case PanelReturnType.AssignAdmin:
					const updateStatusAssign: IAdminStatusUpdate = {
						isAdmin: true,
						UserIds: newTenantAdmins.map((u) => u.id),
					};
					updateAdminStatus(updateStatusAssign);
					setReturnType(null);
					break;
				case PanelReturnType.RemoveAdmin:
					const updateStatusRemove: IAdminStatusUpdate = {
						isAdmin: false,
						UserIds: newTenantAdmins.map((u) => u.id),
					};
					updateAdminStatus(updateStatusRemove);
					setReturnType(null);
					break;
			}
		})()
	}, [returnType]);

	const licenseDropDownOptions = [
		{
			key: 1,
			text: languageStrings.Expired,
		},
		{
			key: 2,
			text: languageStrings.Paid,
		},
		{
			key: 3,
			text: languageStrings.Evaluation,
		},
	];

	const landingPageOptions = [
		{ key: LandingPage.Default, text: languageStrings.Default },
		{ key: LandingPage.SharePoint, text: "SharePoint" },
		{ key: LandingPage.Teams, text: "Teams" },
		{ key: LandingPage.OneDrive, text: "OneDrive" },
		{ key: LandingPage.Exchange, text: "Exchange" }
	]

	const checkIfAllFields = (tenant: ITenant, type: TenantListSections): boolean => {
		let returnVal: boolean = false;
		let list: string[];
		switch (type) {
			case TenantListSections.AdvancedSearch:
				list = tenant.allowedAdvancedSearchFields as string[];
				//if list does not exist, or the length is zero, or if the only element in there is an empty string
				break;
			case TenantListSections.MetaData:
				list = tenant.hiddenMetadataFields as string[];
				break;
			case TenantListSections.Sites:
				list = tenant.allowedSiteCollections as string[];
				break;
			case TenantListSections.Teams:
				list = tenant.allowedTeams as string[];
				break;
		}
		if (!list || list.length === 0 || (list.length === 1 && list[0] === '')) {
			returnVal = true;
		}

		return returnVal;
	};

	const addCallbackPartner = () => {
		if (tenant.id == 0) {
			setReturnType(PanelReturnType.CreateTenant);
		} else {
			setReturnType(PanelReturnType.Add);
		}
	};

	const removeCallbackPartner = () => {
		setReturnType(PanelReturnType.Remove);
	};

	const addCallbackAdmin = () => {
		setReturnType(PanelReturnType.AssignAdmin);
	};

	const removeCallbackAdmin = () => {
		setReturnType(PanelReturnType.RemoveAdmin);
	};

	const getBase64 = (file: any): Promise<string> => {
		return new Promise((resolve) => {
			let baseURL = "";
			let reader = new FileReader();
	
			reader.readAsDataURL(file);
	
			reader.onload = () => {
				baseURL = reader.result as string;
				resolve(baseURL);
			};
		});
	};

	const fileUploaded = async (e: any) => {
		let file = e.target.files[0];
		const res = await getBase64(file);
		const baseParts = res.split(",");

		if (baseParts?.length >= 2) {
			setNewTenant({ ...newTenant, appLogo: res })
		}
	};

	const isDirty = () => {
		let enableSave: boolean = false;
		const tenantKeys = Object.keys(newTenant);
		tenantKeys.every((key) => {
			if (key === 'key') return true; //skip key check, bc we get a new key on rerender
			if (typeof newTenant[key] != 'object') {
				//if it is not an array or object, do this code
				if (newTenant[key] != originalItem[key]) {
					if (key === 'showAllAdvancedSearchFields' && newTenant.showAllAdvancedSearchFields === false && newTenant.allowedAdvancedSearchFields?.length === 0) {
						return true;
					}
					if (key === 'showAllSites' && newTenant.showAllSites === false && newTenant.allowedSiteCollections?.length === 0) {
						return true;
					}
					if (key === 'showAllMetaDataFields' && newTenant.showAllMetaDataFields === false && newTenant.hiddenMetadataFields?.length === 0) {
						return true;
					}
					if (key === 'showAllTeams' && newTenant.showAllTeams === false && newTenant.allowedTeams?.length === 0) {
						return true;
					}
					enableSave = true;
					return false;
				}
				return true;
			} else if (!Array.isArray(newTenant[key])) {
				//if it is an object, do this
				if (newTenant.partner?.id != originalItem.partner?.id) {
					enableSave = true;
					return false;
				}
				return true;
			} else {
				//else we are working with an array
				let originalFields: string[] | string;
				let newFields: string[] | string;
				switch (key) {
					case 'allowedAdvancedSearchFields':
						originalFields = originalItem.allowedAdvancedSearchFields;
						newFields = newTenant.allowedAdvancedSearchFields;
						break;
					case 'allowedTeams':
						originalFields = originalItem.allowedTeams;
						newFields = newTenant.allowedTeams;
						break;
					case 'allowedSiteCollections':
						originalFields = originalItem.allowedSiteCollections;
						newFields = newTenant.allowedSiteCollections;
						break;
					case 'hiddenMetadataFields':
						originalFields = originalItem.hiddenMetadataFields;
						newFields = newTenant.hiddenMetadataFields;
						break;
					default:
						originalFields = [];
						newFields = [];
						break;
				}
				if (newFields?.length != originalFields?.length) {
					enableSave = true;
					return false;
				} else {
					if (newFields && originalFields) {
						for (let i = 0; i < newFields.length; i++) {
							if (newFields[i] != originalFields[i]) {
								enableSave = true;
								return false;
							}
						}
					}
				}
				return true;
			}
		});

		setIsSaveDisabled(!enableSave);
	};

	const admins = [...tenantUsers].filter((tenantUser) => tenantUser.isTenantAdmin);
	const adminPersonas = admins.map((user, i) => {
		return (
			<Persona
				text={user.displayName}
				secondaryText={user.username}
				size={PersonaSize.size40}
				styles={{
					root: {
						marginTop: 10,
					},
				}}
				key={i}
			/>
		);
	});
	const columns: IColumn[] = [
		{
			key: 'displayName',
			name: languageStrings.DisplayName,
			fieldName: 'displayName',
			data: 'string',
			minWidth: 100,
			maxWidth: 350,
			isResizable: true,
		},
	];
	const renderItemColumn = (item: ITenantUser, index: number, column: IColumn) => {
		const fieldContent = item[column.fieldName as keyof ITenantUser] as string;
		switch (column.key) {
			case 'displayName':
				return <Persona text={item.displayName} size={PersonaSize.size40} secondaryText={item.username} />;
			default:
				return <span>{fieldContent}</span>;
		}
	};

	const renderPartnerItemColumn = (item: IPortalPartner, index: number, column: IColumn) => {
		return <Persona text={item.name} size={PersonaSize.size40} />;
	};

	const getFieldNumber = (type: TenantListSections): string => {
		let fieldLength: number;
		let fieldsText: string = '';
		switch (type) {
			case TenantListSections.AdvancedSearch:
				fieldLength = newTenant.allowedAdvancedSearchFields?.length;
				break;
			case TenantListSections.Sites:
				fieldLength = newTenant.allowedSiteCollections?.length;
				break;
			case TenantListSections.Teams:
				fieldLength = newTenant.allowedTeams?.length;
				break;
			case TenantListSections.MetaData:
				fieldLength = newTenant.hiddenMetadataFields?.length;
				let metaDataFieldText = languageStrings.FieldsHidden.replace('{number}', (fieldLength ?? 0).toString());
				if (fieldLength != 1) {
					metaDataFieldText = languageStrings.FieldHidden.replace('{number}', (fieldLength ?? 0).toString()).replace('{fieldOrSite}', languageStrings.Fields);
				} else {
					metaDataFieldText = metaDataFieldText.replace('{fieldOrSite}', languageStrings.Field);
				}
				return metaDataFieldText;
		}
		fieldsText =
			fieldLength != 1
				? languageStrings.FieldsShown.replace('{number}', (fieldLength ?? 0).toString())
				: languageStrings.FieldShown.replace('{number}', fieldLength.toString());
		if (fieldLength != 1) {
			if (type === TenantListSections.Sites) {
				fieldsText = fieldsText.replace('{fieldOrSite}', languageStrings.Sites);
			} else {
				fieldsText = fieldsText.replace('{fieldOrSite}', languageStrings.Fields);
			}
		} else {
			if (type === TenantListSections.Sites) {
				fieldsText = fieldsText.replace('{fieldOrSite}', languageStrings.Site);
			} else {
				fieldsText = fieldsText.replace('{fieldOrSite}', languageStrings.Field);
			}
		}
		return fieldsText;
	};

	const openListPanel = (type: TenantListSections) => {
		let newTenantListPanels = [...tenantListPanels];
		const index = newTenantListPanels.findIndex((panel) => panel.type === type);
		if (index >= 0) {
			newTenantListPanels[index] = { ...newTenantListPanels[index], isOpen: true };
			setTenantListPanels(newTenantListPanels);
		}
	};
	const closeListPanel = (type: TenantListSections) => {
		let newTenantListPanels = [...tenantListPanels];
		const index = newTenantListPanels.findIndex((panel) => panel.type === type);
		if (index >= 0) {
			newTenantListPanels[index] = { ...newTenantListPanels[index], isOpen: false };
			setTenantListPanels(newTenantListPanels);
		}
	};
	return (
		<Panel
			headerText={`${languageStrings.Edit} ${languageStrings.Tenant}`}
			isOpen={isOpen}
			isLightDismiss
			onDismiss={() => {
				setNewTenant(originalItem);
				setIsSaveDisabled(true);
				setIsOpen(false);
			}}
			closeButtonAriaLabel="Close"
			type={PanelType.custom}
			customWidth={'500px'}
			styles={panelStyle}
		>
			<div className={scrollStyle} style={{ marginBottom: 10 }}>
				<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
					<Stack tokens={{ childrenGap: 20 }} style={{ paddingRight: 25 }}>
						{errorMessage && <Stack.Item>
							<MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
						</Stack.Item>}
						<Stack.Item style={{ marginTop: 15, marginBottom: 5 }}>
							<Label styles={labelHeadingStyle}>{languageStrings.FriendlyName}</Label>
							<TextField value={newTenant.friendlyName} onChange={(_, nv) => setNewTenant({ ...newTenant, friendlyName: nv })} onGetErrorMessage={validateName} onBlur={isDirty} styles={{ root: { marginTop: 5 } }} />
						</Stack.Item>
						{isGlobalAdmin && <Stack.Item>
							<Stack horizontal horizontalAlign="space-between">
								<Label styles={labelHeadingStyle}>{languageStrings.AppLogo}</Label>
								{isGlobalAdmin && newTenant.id !== 0 && (
									<CommandBarButton
										iconProps={{
											iconName: 'Edit',
											styles: {
												root: {
													color: 'rgb(50, 49, 48)',
												},
											},
										}}
										text={languageStrings.Change}
										styles={{
											root: {
												backgroundColor: 'transparent',
											},
										}}
										onClick={() => {
											fileUploadInput.current.click();
										}}
									/>
								)}
							</Stack>
							<div>
								<input className="hidden" ref={fileUploadInput} id="cameraFileInput" type="file" accept="image/*" capture="environment" onChange={(e) => fileUploaded(e)} />
								{!newTenant.appLogo && <Label styles={labelInfoStyle} disabled>No logo uploaded</Label>}
								{newTenant.appLogo && <Image src={newTenant.appLogo} width={128} height={"auto"} />}
								{newTenant.appLogo && <Link onClick={() => setNewTenant({ ...newTenant, appLogo: undefined })}>Remove</Link>}
							</div>
						</Stack.Item>}
						{isGlobalAdmin && <Stack.Item>
							<Stack horizontal horizontalAlign="space-between">
								<Label styles={labelHeadingStyle}>{languageStrings.AppColor}</Label>
								{isGlobalAdmin && newTenant.id !== 0 && (
									<CommandBarButton
										iconProps={{
											iconName: 'Edit',
											styles: {
												root: {
													color: 'rgb(50, 49, 48)',
												},
											},
										}}
										text={languageStrings.Change}
										styles={{
											root: {
												backgroundColor: 'transparent',
											},
										}}
										onClick={() => {
											setIsColorPickerPanelOpen(true);
										}}
									/>
								)}
							</Stack>
							{!newTenant.appColor && <Label styles={labelInfoStyle} disabled>No color selected</Label>}
							{newTenant.appColor &&
								<div style={{ backgroundColor: newTenant.appColor, width: 50, height: 50 }}></div>
							}
							{newTenant.appColor && <Link onClick={() => setNewTenant({ ...newTenant, appColor: undefined })}>Remove</Link>}
						</Stack.Item>}
						{isGlobalAdmin && <Stack.Item>
							<Label styles={labelHeadingStyle}>{languageStrings.AppTitle}</Label>
							<TextField value={newTenant.appTitle} onChange={(_, nv) => setNewTenant({ ...newTenant, appTitle: nv })} styles={{ root: { marginTop: 5 } }} />
						</Stack.Item>}
						<Stack.Item>
							<Label styles={labelHeadingStyle}>{languageStrings.DateRegistered}</Label>
							<Label styles={labelInfoStyle} disabled>{`${new Date().toLocaleDateString()}`}</Label>
						</Stack.Item>
						<Stack.Item>
							<Label styles={labelHeadingStyle}>{languageStrings.TenantId}</Label>
							<Label styles={labelInfoStyle} disabled>
								{tenant?.tenantId}
							</Label>
						</Stack.Item>
						<Stack.Item>
							<Label styles={labelHeadingStyle}>{languageStrings.NumberOfUsers}</Label>
							<Label styles={labelInfoStyle} disabled>
								{tenantUsers?.length}
							</Label>
						</Stack.Item>
						<Stack.Item>
							<Label styles={labelHeadingStyle}>{languageStrings.Subscriptions}</Label>
							<Link href="#" onClick={() => {
								browseToSubscription(tenant);
							}}>{`${subscriptionCount} ${languageStrings.ActiveSubscriptions.toLocaleLowerCase()}`}</Link>
						</Stack.Item>
						<Stack.Item style={{ marginBottom: 5 }}>
							<ApiKeyItem tenant={newTenant} updateTenant={updateTenant} />
						</Stack.Item>
						{(isTenantAdmin || isGlobalAdmin) && (
							<Stack>
								<Stack horizontal horizontalAlign="space-between">
									<Label styles={labelHeadingStyle}>{languageStrings.Partner}</Label>
									{isGlobalAdmin && newTenant.id !== 0 && (
										<CommandBarButton
											iconProps={{
												iconName: 'Edit',
												styles: {
													root: {
														color: 'rgb(50, 49, 48)',
													},
												},
											}}
											text={languageStrings.Manage}
											styles={{
												root: {
													backgroundColor: 'transparent',
												},
											}}
											onClick={() => {
												setIsPartnerViewListOpen(true);
											}}
										/>
									)}
								</Stack>
								{newTenant.partner ? (
									<Persona
										text={newTenant.partner?.name}
										//secondaryText={newTenant.partner?.username}
										size={PersonaSize.size40}
										styles={{
											root: {
												marginTop: 10,
											},
										}}
									/>
								) : (
									<Label styles={labelInfoStyle} disabled>
										{!newTenant.partner ? languageStrings.NoPartner : null}
									</Label>
								)}
							</Stack>
						)}
						<Stack.Item style={{ marginBottom: 5 }}>
							<Stack horizontal horizontalAlign="space-between">
								<Label styles={labelHeadingStyle}>{languageStrings.AdminUsers}</Label>
								{tenantUsers?.length > 0 && (
									<CommandBarButton
										iconProps={{
											iconName: 'Edit',
											styles: {
												root: {
													color: 'rgb(50, 49, 48)',
												},
											},
										}}
										text={languageStrings.Manage}
										styles={{
											root: {
												backgroundColor: 'transparent',
											},
										}}
										onClick={() => {
											setIsAdminViewListOpen(true);
										}}
									/>
								)}
							</Stack>
							{adminPersonas.length > 0 ? (
								adminPersonas.length > 3 ? (
									<>
										{adminPersonas.slice(0, 3)}
										<Label
											onClick={() => {
												setIsAdminViewListOpen(true);
											}}
											styles={{
												root: {
													cursor: 'pointer',
													width: 100,
													marginTop: 10,
													selectors: {
														':hover': {
															color: 'rgb(16, 110, 190)',
															backgroundColor: 'rgb(243, 242, 241)',
														},
													},
												},
											}}
										>
											... and {adminPersonas.length - 3} others
										</Label>
									</>
								) : (
									adminPersonas
								)
							) : (
								<Label styles={labelInfoStyle} disabled>
									{languageStrings.NoTenantAdmins}
								</Label>
							)}
						</Stack.Item>
						<Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.Locations}</Label> {/*add in settings */}
							<Toggle label={languageStrings.Show + ' SharePoint'} checked={newTenant.enableSharePoint} onChange={() => setNewTenant({ ...newTenant, enableSharePoint: !newTenant.enableSharePoint })} onText={languageStrings.Yes} offText={languageStrings.No} />
							<Toggle label={languageStrings.Show + ' Teams'} checked={newTenant.enableTeams} onChange={() => setNewTenant({ ...newTenant, enableTeams: !newTenant.enableTeams })} onText={languageStrings.Yes} offText={languageStrings.No} />
							<Toggle label={languageStrings.Show + ' OneDrive'} checked={newTenant.enableOneDrive} onChange={() => setNewTenant({ ...newTenant, enableOneDrive: !newTenant.enableOneDrive })} onText={languageStrings.Yes} offText={languageStrings.No} />
							<Toggle label={languageStrings.EnableApprovals} checked={newTenant.enableApprovals} onChange={() => setNewTenant({ ...newTenant, enableApprovals: !newTenant.enableApprovals })} onText={languageStrings.Yes} offText={languageStrings.No} />
							<Toggle
								label={languageStrings.AllowOpenInSharePoint}
								checked={newTenant.allowOpenInSharePoint ?? true}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, allowOpenInSharePoint: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<Toggle
								label={languageStrings.HideAutoPreview}
								checked={newTenant.hideAutoPreview ?? false}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, hideAutoPreview: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<Stack.Item>
								<Label styles={labelHeadingStyle}>{languageStrings.LandingPage}</Label>
								<Dropdown
									style={{ marginTop: 7 }}
									styles={dropdownStyles}
									selectedKey={newTenant?.landingPage ? newTenant?.landingPage?.valueOf() : landingPageOptions[0].key}
									onChange={(_e, o) => setNewTenant({ ...newTenant, landingPage: Number(o.key) })}
									options={landingPageOptions}
								/>
							</Stack.Item>
						</Stack.Item>
						<Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.OutlookSettings}</Label>
							<Toggle disabled={true} label={languageStrings.SaveOnSend} checked={newTenant.saveOnSend ? true : false} onChange={() => setNewTenant({ ...newTenant, saveOnSend: !newTenant.saveOnSend })} onText={languageStrings.Yes} offText={languageStrings.No} />
							<Toggle
								label={languageStrings.AutoSelectAttachments}
								checked={newTenant.autoSelectAttachments ? true : false}
								onChange={() => setNewTenant({ ...newTenant, autoSelectAttachments: !newTenant.autoSelectAttachments })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<Toggle
								label={languageStrings.AutoRemoveMailItemAttachments}
								checked={newTenant.autoRemoveMailItemAttachments ? true : false}
								onChange={() => setNewTenant({ ...newTenant, autoRemoveMailItemAttachments: !newTenant.autoRemoveMailItemAttachments })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<Toggle
								label={languageStrings.ConvertEmailToPdf}
								checked={newTenant.convertEmailToPDF ? true : false}
								onChange={() => setNewTenant({ ...newTenant, convertEmailToPDF: !newTenant.convertEmailToPDF })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
						</Stack.Item>
						<Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.ExchangeSettings}</Label>
							<Toggle label={languageStrings.Enable} checked={newTenant.enableExchange ? true : false} onChange={() => setNewTenant({ ...newTenant, enableExchange: !newTenant.enableExchange })} onText={languageStrings.Yes} offText={languageStrings.No} />
							<Label
								styles={{
									root: {
										fontWeight: FontWeights.regular,
									},
								}}
							>
								{languageStrings.EmailHeaderMapping}
							</Label>
							<DefaultButton
								disabled={!newTenant.enableExchange}
								onClick={() => {
									setIsEmailMappingPanelOpen(true);
								}}
								style={{ marginTop: 10 }}
							>
								{languageStrings.Manage}
							</DefaultButton>
						</Stack.Item>
						<ListToggleSection
							heading={languageStrings.TeamSettings}
							toggleLabel={languageStrings.ShowAllTeams}
							numberOfFields={getFieldNumber(TenantListSections.Teams)}
							toggleSetterCallback={() => setNewTenant({ ...newTenant, showAllTeams: !newTenant.showAllTeams })}
							toggleValue={newTenant.showAllTeams ?? true}
							hasEditButton
							editButtonCallback={() => {
								openListPanel(TenantListSections.Teams);
							}}
							editButtonText={languageStrings.Manage}
							mainStyle={sectionSeparator}
						/>
						<ListToggleSection
							heading={languageStrings.MetaDataSettings}
							toggleLabel={languageStrings.ShowAllMetadata}
							numberOfFields={getFieldNumber(TenantListSections.MetaData)}
							toggleSetterCallback={() => setNewTenant({ ...newTenant, showAllMetaDataFields: !newTenant.showAllMetaDataFields })}
							toggleValue={newTenant.showAllMetaDataFields ?? true}
							hasEditButton
							editButtonCallback={() => {
								openListPanel(TenantListSections.MetaData);
							}}
							editButtonText={languageStrings.Manage}
							mainStyle={sectionSeparator}
						/>
						<ListToggleSection
							heading={languageStrings.AdvancedSearchSettings}
							toggleLabel={languageStrings.ShowAllFields}
							numberOfFields={getFieldNumber(TenantListSections.AdvancedSearch)}
							toggleSetterCallback={() => setNewTenant({ ...newTenant, showAllAdvancedSearchFields: !newTenant.showAllAdvancedSearchFields })}
							toggleValue={newTenant.showAllAdvancedSearchFields ?? true}
							hasEditButton
							editButtonCallback={() => {
								openListPanel(TenantListSections.AdvancedSearch);
							}}
							editButtonText={languageStrings.Manage}
							mainStyle={sectionSeparator}
						/>
						<Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.DocumentSettings}</Label>
							<Toggle
								label={languageStrings.DisableDocumentRename}
								checked={newTenant.disableFileRename ? true : false}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, disableFileRename: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<Toggle
								label={languageStrings.OpenDocumentsInClientApp}
								checked={newTenant.openDocumentsInClientApp ? true : false}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, openDocumentsInClientApp: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<Toggle
								label={languageStrings.EnableCopyMoveTo}
								checked={newTenant.enableCopyMoveTo ? true : false}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, enableCopyMoveTo: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<Toggle
								label={languageStrings.EnableSharing}
								checked={newTenant.enableSharing ? true : false}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, enableSharing: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
						</Stack.Item>
						{/* <Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.PermissionSettings}</Label>
							<Toggle
								label={languageStrings.EnablePermissionManagent}
								checked={newTenant.enablePermissionManagement ? true : false}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, enablePermissionManagement: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
						</Stack.Item> */}
						{/* <Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.PIISettings}</Label>
							<Toggle
								label={languageStrings.EnablePII}
								checked={newTenant.enablePII ?? true}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, enablePII: checked ?? false, enableRedaction: checked ? newTenant.enableRedaction : false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							{newTenant.enablePII && (
								<Toggle
									label={languageStrings.EnablePIIRedaction}
									checked={newTenant.enableRedaction ?? true}
									onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, enableRedaction: checked ?? false })}
									onText={languageStrings.Yes}
									offText={languageStrings.No}
								/>
							)}
							<Label
								styles={{
									root: {
										fontWeight: FontWeights.regular,
									},
								}}
							>
								{`${newTenant.piiInfoTypes ? newTenant.piiInfoTypes.split(';')?.length : 0} ${languageStrings.InfoTypesSelected}`}
							</Label>
							<DefaultButton
								disabled={!newTenant.enablePII}
								onClick={() => {
									setIsSelectPiiInfoTypesPanelOpen(true);
								}}
								style={{ marginTop: 10 }}
							>
								{languageStrings.Manage}
							</DefaultButton>
						</Stack.Item> */}
						<Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.OcrSettings}</Label>
							<Label styles={labelInfoStyle}>{`${languageStrings.Status}: ${newTenant?.ocrLanguage ? languageStrings.Enabled : languageStrings.Disabled}`}</Label>
							<Dropdown
								label={languageStrings.OcrLanguage}
								disabled={!(isGlobalAdmin || isPartner)}
								styles={{
									root: {
										marginTop: 7,
									},
								}}
								selectedKey={newTenant?.ocrLanguage?.valueOf()}
								onChange={(_e, o) => setNewTenant({ ...newTenant, ocrLanguage: o.key?.toString() })}
								options={ocrLanguageDropDownOptions}
							/>
						</Stack.Item>
						<Stack.Item style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.NewTemplateSettings}</Label>
							<Toggle
								label={languageStrings.EnableNewTemplateFilters}
								checked={newTenant.enableNewTemplateFilters ?? true}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, enableNewTemplateFilters: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							{newTenant.enableNewTemplateFilters && (
								<>
									<Dropdown
										multiSelect
										label={languageStrings.InsideDocumentLibrary}
										disabled={!(isGlobalAdmin || isPartner)}
										styles={{
											root: {
												marginTop: 7,
											},
										}}
										selectedKeys={newTenant?.newTemplateLibraryFilters ? newTenant?.newTemplateLibraryFilters.split(';') : []}
										onChange={(_e, o) => {
											const options = newTenant?.newTemplateLibraryFilters ? newTenant?.newTemplateLibraryFilters.split(';') : [];
											if (options.includes(o.key as string) && o.selected === false) {
												setNewTenant({ ...newTenant, newTemplateLibraryFilters: options.filter((x) => x !== (o.key as string)).join(';') });
											}
											if (!options.includes(o.key as string) && o.selected === true) {
												options.push(o.key as string);
												setNewTenant({ ...newTenant, newTemplateLibraryFilters: options.join(';') });
											}
										}}
										options={newTemplateTypesDropDownOptions}
									/>
									<Dropdown
										multiSelect
										label={languageStrings.InsideFolder}
										disabled={!(isGlobalAdmin || isPartner)}
										styles={{
											root: {
												marginTop: 7,
											},
										}}
										selectedKeys={newTenant?.newTemplateFolderFilters ? newTenant?.newTemplateFolderFilters.split(';') : []}
										onChange={(_e, o) => {
											const options = newTenant?.newTemplateFolderFilters ? newTenant?.newTemplateFolderFilters.split(';') : [];
											if (options.includes(o.key as string) && o.selected === false) {
												setNewTenant({ ...newTenant, newTemplateFolderFilters: options.filter((x) => x !== (o.key as string)).join(';') });
											}
											if (!options.includes(o.key as string) && o.selected === true) {
												options.push(o.key as string);
												setNewTenant({ ...newTenant, newTemplateFolderFilters: options.join(';') });
											}
										}}
										options={newTemplateTypesDropDownOptions}
									/>
									<Dropdown
										multiSelect
										label={languageStrings.InsideDocumentSet}
										disabled={!(isGlobalAdmin || isPartner)}
										styles={{
											root: {
												marginTop: 7,
											},
										}}
										selectedKeys={newTenant?.newTemplateDocumentSetFilters ? newTenant?.newTemplateDocumentSetFilters.split(';') : []}
										onChange={(_e, o) => {
											const options = newTenant?.newTemplateDocumentSetFilters ? newTenant?.newTemplateDocumentSetFilters.split(';') : [];
											if (options.includes(o.key as string) && o.selected === false) {
												setNewTenant({ ...newTenant, newTemplateDocumentSetFilters: options.filter((x) => x !== (o.key as string)).join(';') });
											}
											if (!options.includes(o.key as string) && o.selected === true) {
												options.push(o.key as string);
												setNewTenant({ ...newTenant, newTemplateDocumentSetFilters: options.join(';') });
											}
										}}
										options={newTemplateTypesDropDownOptions.filter((x) => x.key !== 'docset')}
									/>
								</>
							)}
						</Stack.Item>
						<Stack.Item>
							<Label styles={labelHeadingStyle}>{languageStrings.VersionSettings}</Label>
							<TextField
								label={languageStrings.MinimumVersionLimit}
								value={newTenant.minVersionLimit?.toString()}
								type="number"
								onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
									let newLimit = +newValue < 0 ? 0 : +newValue;
									setNewTenant({ ...newTenant, minVersionLimit: newLimit });
								}}
							/>
							<Toggle
								label={languageStrings.AutoDeleteMinorVersionsOnPublish}
								checked={newTenant.autoDeleteMinorVersionsOnPublish ?? true}
								onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenant({ ...newTenant, autoDeleteMinorVersionsOnPublish: checked ?? false })}
								onText={languageStrings.Yes}
								offText={languageStrings.No}
							/>
							<TextField
								label={languageStrings.RequiredMetadataOnPublish}
								value={newTenant.requiredMetadataOnPublish}
								type="text"
								onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
									setNewTenant({ ...newTenant, requiredMetadataOnPublish: newValue });
								}}
							/>
						</Stack.Item>
						{/* <Stack>
							<ListToggleSection
								heading={languageStrings.SiteSettings}
								toggleLabel={languageStrings.ShowAllSites}
								numberOfFields={getFieldNumber(TenantListSections.Sites)}
								toggleSetterCallback={() => setNewTenant({ ...newTenant, showAllSites: !newTenant.showAllSites })}
								toggleValue={newTenant.showAllSites ?? true}
								hasEditButton
								editButtonCallback={() => {
									openListPanel(TenantListSections.Sites);
								}}
								editButtonText={languageStrings.Manage}
								mainStyle={sectionSeparator}
							/>
							<Stack horizontalAlign="start" style={{ marginTop: 10 }}>
								<DefaultButton onClick={() => setIsManageSitesOpen(true)}>{languageStrings.AdvancedSiteSettings}</DefaultButton>
							</Stack>
						</Stack> */}
						<Stack style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.SiteSettings}</Label>
							<Label
								styles={{
									root: {
										fontWeight: FontWeights.regular,
									},
								}}
							>
								{languageStrings.SitesLibrariesConfigured.replace('{siteCount}', tenant?.sites?.length.toString() ?? '0').replace('{libraryCount}', tenantSiteLibrariesCount.toString())}
							</Label>
							<div>
								<DefaultButton
									onClick={() => {
										setIsManageSitesOpen(true);
									}}
									style={{ marginTop: 10 }}
								>
									{languageStrings.Manage}
								</DefaultButton>
							</div>
						</Stack>
						<Stack style={sectionSeparator}>
							<Label styles={labelHeadingStyle}>{languageStrings.DataSources}</Label>
							<Label
								styles={{
									root: {
										fontWeight: FontWeights.regular,
									},
								}}
							>
								{languageStrings.DatasourceNumber.replace('{number}', tenant?.datasources?.length.toString() ?? '0')}
							</Label>
							<div>
								<DefaultButton
									onClick={() => {
										setIsDataSourcePanelOpen(true);
									}}
									style={{ marginTop: 10 }}
								>
									{languageStrings.Manage}
								</DefaultButton>
							</div>
						</Stack>
					</Stack>
				</ScrollablePane>
			</div>
			<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>

				<PrimaryButton
					styles={panelActionButtonStyle}
					disabled={newTenant.id === 0 ? (isGlobalAdmin ? false : true) : isSaveDisabled}
					iconProps={{ iconName: 'save' }}
					onClick={async () => {
						setErrorMessage("");
						if (tenant?.id === 0) {
							try {
								const results = await addTenantAPI(newTenant);
								if (results.success) {
									setIsOpen(false); //close panel
									addTenant(results.tenant, false);
									closeOnAddSuccess();
								} else {
									setErrorMessage(results.message);
								}
							} catch (error) {

							}
						} else {
							updateTenant(newTenant, tenant); //local state
							setIsOpen(false); //close panel
						}
					}}
				>
					{newTenant.id === 0 ? languageStrings.Create : languageStrings.Save}
				</PrimaryButton>
				{/* {isPartner && (
					<DefaultButton
						styles={panelActionButtonStyle}
						iconProps={{ iconName: 'delete' }}
						onClick={async () => {
							setShowDeleteDialog(true);
							setIsOpen(false); //close panel
						}}
					>
						{languageStrings.Delete}
					</DefaultButton>
				)} */}
				{isGlobalAdmin && tenant.id > 0 && tenantUsers.length == 0 ? (
					<DefaultButton
						styles={panelActionButtonStyle}
						iconProps={{ iconName: 'delete' }}
						onClick={async () => {
							setShowGlobalDeleteDialog(true);
							setIsOpen(false); //close panel
						}}
					>
						{languageStrings.Delete}
					</DefaultButton>
				) : null}
			</Stack>
			{isPartnerViewListOpen && (
				<ViewListPanel
					isOpen={isPartnerViewListOpen}
					setIsOpen={setIsPartnerViewListOpen}
					text={languageStrings.SelectPartner}
					addCallback={addCallbackPartner}
					removeCallback={removeCallbackPartner}
					columns={columns}
					renderItemColumn={renderPartnerItemColumn}
					shortList={[newTenant?.partner]}
					fullList={partners}
					title={newTenant?.friendlyName}
					addText={languageStrings.Change}
					removeText={languageStrings.Remove}
					multiSelect={false}
					addTitle={languageStrings.SelectPartner}
					indicateNumbers={false}
					listSetter={setNewPartner}
					escapeAddPanelAfterAction={true}
				/>
			)}
			{isAdminViewListOpen && (
				<ViewListPanel
					isOpen={isAdminViewListOpen}
					setIsOpen={setIsAdminViewListOpen}
					text={languageStrings.SelectTenantAdmin}
					addCallback={addCallbackAdmin}
					removeCallback={removeCallbackAdmin}
					columns={columns}
					renderItemColumn={renderItemColumn}
					shortList={tenantUsers.filter((u) => u.isTenantAdmin)}
					fullList={tenantUsers.filter((u) => !u.isTenantAdmin)}
					title={newTenant?.friendlyName}
					addText={languageStrings.Add}
					removeText={languageStrings.Remove}
					multiSelect={true}
					addTitle={languageStrings.SelectTenantAdmin}
					indicateNumbers={true}
					listSetter={setNewTenantAdmins}
				/>
			)}
			{/* {isSelectPiiInfoTypesPanelOpen && <SelectPiiInfoTypesPanel tenant={tenant} isOpen={isSelectPiiInfoTypesPanelOpen} closePanel={() => setIsSelectPiiInfoTypesPanelOpen(false)} updateTenant={updateTenant} />} */}
			{/* Advanced Fields */}
			{tenantListPanels.find((panel) => panel.type == TenantListSections.AdvancedSearch)?.isOpen && <OrderedFieldsPanel
				isOpen={tenantListPanels.find((panel) => panel.type == TenantListSections.AdvancedSearch)?.isOpen}
				closePanel={() => closeListPanel(TenantListSections.AdvancedSearch)}
				title={newTenant?.friendlyName}
				subtitle={languageStrings.ManageAdvancedSearchFields}
				initialList={typeof newTenant?.allowedAdvancedSearchFields === 'string' ? Array.from([newTenant?.allowedAdvancedSearchFields]) : newTenant?.allowedAdvancedSearchFields}
				okCallback={(list) => setNewTenant({ ...newTenant, allowedAdvancedSearchFields: list })}
				type={TenantListSections.AdvancedSearch}
				showMoveButtons
			/>}
			{/* Teams Fields */}
			{tenantListPanels.find((panel) => panel.type == TenantListSections.Teams)?.isOpen && <OrderedFieldsPanel
				isOpen={tenantListPanels.find((panel) => panel.type == TenantListSections.Teams)?.isOpen}
				closePanel={() => closeListPanel(TenantListSections.Teams)}
				title={newTenant?.friendlyName}
				subtitle={languageStrings.ManageTeamsFields}
				initialList={typeof newTenant?.allowedTeams === 'string' ? Array.from([newTenant?.allowedTeams]) : newTenant?.allowedTeams}
				okCallback={(list) => {
					const updatedTenant: ITenant = { ...newTenant, allowedTeams: list }
					setNewTenant(updatedTenant);
					updateTenant(updatedTenant, tenant);
				}}
				type={TenantListSections.Teams}
			/>}
			{/* Sites Fields */}
			{tenantListPanels.find((panel) => panel.type == TenantListSections.Sites)?.isOpen && <OrderedFieldsPanel
				isOpen={tenantListPanels.find((panel) => panel.type == TenantListSections.Sites)?.isOpen}
				closePanel={() => closeListPanel(TenantListSections.Sites)}
				title={newTenant?.friendlyName}
				subtitle={languageStrings.ManageSites}
				initialList={typeof newTenant?.allowedSiteCollections === 'string' ? Array.from([newTenant?.allowedSiteCollections]) : newTenant?.allowedSiteCollections}
				okCallback={(list) => setNewTenant({ ...newTenant, allowedSiteCollections: list })}
				type={TenantListSections.Sites}
			/>}
			{/* MetaData Fields */}
			{tenantListPanels.find((panel) => panel.type == TenantListSections.MetaData)?.isOpen && <OrderedFieldsPanel
				isOpen={tenantListPanels.find((panel) => panel.type == TenantListSections.MetaData)?.isOpen}
				closePanel={() => closeListPanel(TenantListSections.MetaData)}
				title={newTenant?.friendlyName}
				subtitle={languageStrings.ManageMetaData}
				initialList={typeof newTenant?.hiddenMetadataFields === 'string' ? Array.from([newTenant?.hiddenMetadataFields]) : newTenant?.hiddenMetadataFields}
				okCallback={(list) => setNewTenant({ ...newTenant, hiddenMetadataFields: list })}
				type={TenantListSections.MetaData}
			/>}
			{/* Manage advanced sites */}
			<ManageSitesPanel isOpen={isManageSitesOpen} dismissPanel={() => setIsManageSitesOpen(false)} tenant={newTenant} />
			<ColorPickerPanel
				isOpen={isColorPickerPanelOpen}
				dismissPanel={() => setIsColorPickerPanelOpen(false)}
				tenant={newTenant}
				colorChanged={(color: string) => {
					setNewTenant({ ...newTenant, appColor: color });
				}}
			/>
			<DataSourcePanel isOpen={isDataSourcePanelOpen} dismissPanel={() => setIsDataSourcePanelOpen(false)} tenant={tenant} />
			{isEmailMappingPanelOpen && <EmailMappingPanel
				isOpen={isEmailMappingPanelOpen}
				emailToMetadataMapping={newTenant.emailToMetadataMapping}
				closePanel={() => setIsEmailMappingPanelOpen(false)}
				closeOnSave={(compressedData: string, clearUserEmailMappings: boolean) => {
					const updatedTenant: ITenant = { ...newTenant, emailToMetadataMapping: compressedData }
					setNewTenant(updatedTenant);
					updateTenant(updatedTenant, tenant);
					if (clearUserEmailMappings) {
						clearTenantUserEmailMappings(updatedTenant.tenantId);
					}

					setIsEmailMappingPanelOpen(false);
				}}
			/>}
		</Panel>
	);
};

export default connector(EditTenantPanel);
