
import { Stack, TextField, mergeStyleSets, getTheme, Checkbox, } from '@fluentui/react';
import React from 'react';
const theme = getTheme();
const selectedColor = theme.palette.neutralLighter;

const baseStyle = {
    // borderBottomColor: theme.palette.neutralLight,
    // borderBottomWidth: 0.5,
    // borderBottomStyle: 'solid',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    width: "100%",
}
const orderedListStyles = mergeStyleSets({
    selected: {
        ...baseStyle,
        backgroundColor: selectedColor,
    },
    standard: {
        ...baseStyle,
    }
})

interface OrderedListItemProps {
    value: string,
    index: number,
    isSelected: boolean,
    setIsSelected: (index: number, value?: boolean) => void,
    setFieldValue: (index: number, value: string) => void,
    onEnter: () => void
};

const OrderedListItem: React.FC<OrderedListItemProps> = ({
    value,
    index,
    isSelected,
    setIsSelected,
    setFieldValue,
    onEnter,
}: OrderedListItemProps) => {
    const [textValue, setTextValue] = React.useState(value);
    const [allowEnter, setAllowEnter] = React.useState(false);

    React.useEffect(() => {
        if (allowEnter) {
            onEnter();
            setAllowEnter(false);
        }
    }, [allowEnter])

    return (
        <Stack
            horizontal
            verticalAlign="center"
            className={isSelected ? orderedListStyles.selected : orderedListStyles.standard}
            tokens={{childrenGap: 10}}
        >
            <Checkbox checked={isSelected} onChange={(e, isChecked) => {
                setIsSelected(index, isChecked)
            }}></Checkbox>
            <TextField
                value={textValue}
                styles={{
                    root: {
                        width: "100%"
                    }
                }}
                onChange={(_, nv) => {
                    setTextValue(nv);
                }}
                onBlur={() => {
                    setFieldValue(index, textValue.trim());
                }}
                onKeyDown={(event) => {
                    if (event.code === "Enter") {
                        setTextValue(event?.currentTarget?.value.trim() ?? textValue.trim());
                        setFieldValue(index, event?.currentTarget?.value.trim() ?? textValue.trim());
                        setAllowEnter(true);
                    }
                }}
            />
        </Stack>
    )
}

export default OrderedListItem;