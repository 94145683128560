
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, DefaultButton, Label, CommandBarButton, PanelType, Spinner, getTheme, FontSizes, PrimaryButton, mergeStyles, FontWeights, ICommandBarStyles, CommandBar, ICommandBarItemProps, TextField, mergeStyleSets } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { errorLabelStyle, panelActionButtonStyle, panelCommandButtonStyle } from "../../styles/PanelStyle"
import OrderedListItem from "../OrderedListitem"
import { TenantListSections } from '../../data-structures/enums';

const theme = getTheme();

const labelStyle = mergeStyles({
    marginBottom: 10,
    borderBottomColor: 'rgb(237, 235, 233)',
    borderBottomWidth: 0.5,
    borderBottomStyle: 'solid'
})

const fieldsContainerStyle = mergeStyles({
    borderColor: theme.palette.neutralLight,
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 10,
})

const labelContainerStyle = mergeStyles({
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 5
})

const commandBarStyle: Partial<ICommandBarStyles> = {
    root: {
        // borderTop: '1px solid rgb(237, 235, 233);',
        borderBottom: '1px solid rgb(237, 235, 233);',
        paddingLeft: 0,
        paddingRight: 10,
        paddingBottom: 3,
        paddingTop: 3
    }
};

const baseStyle = {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    width: "100%",
}
const orderedListStyles = mergeStyleSets({
    standard: {
        ...baseStyle,
    }
})

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.user.isUserLoading
    };
};

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface OrderedFieldsPanelProps extends PropsFromRedux {
    isOpen: boolean,
    closePanel: () => void,
    subtitle: string,
    title: string,
    okCallback: (list: string[]) => void,
    initialList: string[],
    showMoveButtons?: boolean,
    type: TenantListSections
};

const OrderedFieldsPanel: React.FC<OrderedFieldsPanelProps> = ({
    isOpen,
    closePanel,
    title,
    subtitle,
    isLoading,
    initialList,
    okCallback,
    showMoveButtons = false,
    type,
}: OrderedFieldsPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);
    // const [currentIndexSelected, setCurrentIndexSelected] = React.useState<number>(null);
    const [selectedIndexes, setSelectedIndexes] = React.useState<number[]>([]);
    const [isOkayDisabled, setIsOkayDisabled] = React.useState<boolean>(true);
    //const [list, setList] = React.useState<string[]>();
    const [list, setList] = React.useState<string[]>(initialList?.length >= 1 ? initialList : []);
    const [fields, setFields] = React.useState<JSX.Element[]>(null);
    const [errorMessage, setErrorMessage] = React.useState<string>(null);
    const [commandbarItems, setCommandbarItems] = React.useState<ICommandBarItemProps[]>([]);

    const [inAddMode, setInAddMode] = React.useState(false);
    const [newFieldValue, setNewFieldValue] = React.useState("");

    //On Enter to submit
    React.useEffect(() => {
        if (list) {
            setFields(list.map((field, index) => {
                return (
                    <OrderedListItem
                        onEnter={onEnter}
                        index={index}
                        isSelected={selectedIndexes.some(x => x === index)}
                        setFieldValue={updateValue}
                        setIsSelected={updateSelectedIndexes}
                        value={field}
                        key={`${field} + ${index}`}
                    />
                )
            }))
        }

    }, [selectedIndexes]);

    const updateSelectedIndexes = (index: number, isSelected?: boolean) => {
        if (isSelected) {
            setSelectedIndexes(prev =>  prev.concat(index))
        } else {
            setSelectedIndexes(prev =>  prev.filter(x => x !== index))
        }
    }

    React.useEffect(() => {
        setNewFieldValue("");
    }, [inAddMode])

    React.useEffect(() => {
        const items: ICommandBarItemProps[] = [];

        items.push(
            {
                key: 'add',
                iconProps: { iconName: "add" },
                style: { paddingTop: 5, paddingBottom: 5, },
                disabled: inAddMode,
                text: languageStrings.Add,
                onClick: () => {
                    //addField();
                    setInAddMode(true);
                }
            },
            {
                key: 'delete',
                iconProps: { iconName: "delete" },
                style: { paddingTop: 5, paddingBottom: 5, },
                disabled: inAddMode || selectedIndexes.length === 0,
                text: languageStrings.Delete,
                onClick: () => {
                    removeField();
                    setSelectedIndexes([]);
                }
            }
        )

        if (showMoveButtons && selectedIndexes.length === 1 && fields?.length > 1) {
            items.push(
                {
                    key: 'moveUp',
                    iconProps: { iconName: "chevronUp" },
                    style: { paddingTop: 5, paddingBottom: 5, },
                    disabled: selectedIndexes[0] === 0,
                    onClick: () => {
                        moveField(selectedIndexes[0], -1);
                    }
                },
                {
                    key: 'moveDown',
                    iconProps: { iconName: "chevronDown" },
                    style: { paddingTop: 5, paddingBottom: 5, },
                    disabled: !fields || selectedIndexes[0] === fields.length - 1,
                    onClick: () => {
                        moveField(selectedIndexes[0], 1);
                    }
                }
            )
        }

        setCommandbarItems(items);
    }, [showMoveButtons, selectedIndexes, inAddMode, fields]);

    React.useEffect(() => {
        setIsOkayDisabled(!canPressOkay());
    }, [list])

    const columnHeader: string = React.useMemo(() => {
        switch (type) {
            case TenantListSections.AdvancedSearch:
            case TenantListSections.MetaData:
                return languageStrings.FieldName;
            case TenantListSections.Teams:
                return languageStrings.TeamName;
            case TenantListSections.Sites:
                return languageStrings.SiteName;
            default:
                return languageStrings.FieldName;
        }
    }, [type])

    const onEnter = () => {
        if (canPressOkay()) {
            okCallback(list);
            closePanel(); //close panel
        }
    }

    const updateFields = (newSearchFields: string[]) => {
        setList(newSearchFields);
        setFields(newSearchFields.map((field, index) => {
            return (
                <OrderedListItem
                    onEnter={onEnter}
                    index={index}
                    isSelected={selectedIndexes.some(x => x === index)}
                    setFieldValue={updateValue}
                    setIsSelected={updateSelectedIndexes}
                    value={field}
                    key={`${field} + ${index}`}
                />
            )
        }))
        //toggleUpdate();
    }

    const addField = (newField: string) => {
        let newSearchFields: string[] = [newField]; 
        if (list) {
            newSearchFields = [...list].concat(newField);
        }
        updateFields(newSearchFields);
        okCallback(newSearchFields);
    }

    const removeField = () => {
        let newSearchFields = [...list].filter((field, index) => !selectedIndexes.some(x => x === index)); //delete index;
        updateFields(newSearchFields);
        okCallback(newSearchFields);
    }

    const updateValue = (updateIndex: number, newValue: string) => {
        if (updateIndex >= 0 && updateIndex < list.length) {
            const newSearchFields = [...list];
            newSearchFields[updateIndex] = newValue;
            updateFields(newSearchFields);
        }
    }

    const moveField = (moveIndex: number, indexChange: number) => { //indexChange is +1 if we are moving the item down in the last, and -1 if we move it up the list
        let newSearchFields = [...list];
        const newIndex = moveIndex + indexChange;
        if (moveIndex + indexChange >= 0 && newIndex < newSearchFields.length) {
            const tmp = newSearchFields[moveIndex];
            newSearchFields[moveIndex] = newSearchFields[newIndex]
            newSearchFields[newIndex] = tmp;
        }

        setSelectedIndexes([newIndex]);
        updateFields(newSearchFields);
    }

    const canPressOkay = (): boolean => {
        let returnVal = false;
        if (list?.length === 0) {
            setErrorMessage(null);
            return false;
        }
        if (list && !list?.some(fieldText => !fieldText)) { //check if all the strings in the array are not empty or null

            const lowerCaseList = list.map(item => item.toLowerCase());
            const listSet = new Set(lowerCaseList);
            if (listSet.size === lowerCaseList.length) {
                returnVal = true;
                setErrorMessage(null);
            } else {
                //fid the duplicate item
                const duplicates = Array.from(listSet).filter(item => {
                    if (lowerCaseList.filter(nonUniqueItem => nonUniqueItem === item).length > 1) {
                        return item;
                    }
                    return null;
                });
                setErrorMessage(languageStrings.DuplicatesFound + " " + duplicates.join(", "));
            }
        } else {
            setErrorMessage(languageStrings.NoEmptyFields);
        }
        return returnVal;
    }

    return (
        <Panel
            headerText={title}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                closePanel();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Label className={labelStyle}>{subtitle}</Label>
            <CommandBar
                styles={commandBarStyle}
                items={commandbarItems || []}
            />
            {!inAddMode && fields?.length > 0 ? <Stack className={fieldsContainerStyle}>
                <Stack horizontal horizontalAlign="space-between" className={labelContainerStyle}>
                    <Label styles={{ root: { fontSize: FontSizes.medium } }}>
                        {columnHeader}
                    </Label>
                </Stack>
                {fields}
            </Stack>
                : null}
            {inAddMode &&
                <Stack className={fieldsContainerStyle}>
                    <Stack horizontal horizontalAlign="space-between" className={labelContainerStyle}>
                        <Label styles={{ root: { fontSize: FontSizes.medium } }}>
                            {`${languageStrings.New} ${columnHeader.toLowerCase()}`}
                        </Label>
                    </Stack>
                    <div style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15}}>
                        <TextField 
                            value={newFieldValue} 
                            onChange={(e, v) => {
                                setNewFieldValue(v);
                                const duplicates = list.some(x => x.toLowerCase() === v.toLowerCase());
                                if (duplicates) {
                                    setErrorMessage(languageStrings.DuplicatesFound.replace(":", ""));
                                } else {
                                    setErrorMessage("");
                                }
                                
                            }}
                        />
                    </div>
                </Stack>
            }
            {errorMessage ?
                <Label className={errorLabelStyle}>
                    {errorMessage}
                </Label>
                : null}
            {(fields?.length > 0 || inAddMode) && <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                <PrimaryButton styles={panelActionButtonStyle}
                    disabled={(!inAddMode && isOkayDisabled) || (inAddMode && (!newFieldValue || errorMessage !== ""))}
                    iconProps={{ iconName: inAddMode ? "Save" : "CheckMark" }}
                    onClick={async () => {
                        if (inAddMode) {
                            addField(newFieldValue);
                            setInAddMode(false);
                        } else {
                            okCallback(list);
                            closePanel();
                        }
                    }}>
                    {inAddMode ? languageStrings.Save : languageStrings.Ok}
                </PrimaryButton>
                <DefaultButton styles={panelActionButtonStyle}
                    iconProps={{ iconName: "Cancel" }}
                    onClick={async () => {
                        if (inAddMode) {
                            setInAddMode(false);
                            setErrorMessage("");
                        } else {
                            closePanel();
                        }

                    }}>
                    {languageStrings.Cancel}
                </DefaultButton>
            </Stack>}
        </Panel>
    )
}

export default connector(OrderedFieldsPanel);
