import * as React from 'react';
import { LanguageContext } from '../utilities/LocalizationModule';
import TopNavigator from "../components/TopNavigator"
import { DefaultButton, Stack } from "@fluentui/react"
import { HomeView } from "../data-structures/enums"
import UsersTable from '../components/displays/UsersTable';
import { connect, ConnectedProps } from 'react-redux';
import { IPortalUser, ITenant } from '../data-structures/interfaces';
import { getCurrentProfile } from '../utilities/helpers/ApiHelper';
import { setUserProfile } from "../redux/modules/user"
import { getRequests } from "../redux/modules/requests"
import { getDatasourceTypes } from "../redux/modules/datasource"
import Dashboard from '../components/displays/Dashboard';
import Subscriptions from '../components/displays/Subsriptions';
import MessageScreen from "./MessageScreen"
import NavMenu from "../components/NavMenu"
import { MainViewStyle } from "../styles/HomeStyles"
import { useMsal } from '@azure/msal-react';
import Loading from '../components/Loading';
import { RootState } from '../redux';
import PartnersOverview from "../components/displays/PartnersOverview";
import Requests from "../components/displays/Requests";

const mapDispatchToProps = {
    setUserProfile,
    getRequests,
    getDatasourceTypes
};

const mapStateToProps = (state: RootState) => {
    return {
        requests: state.requests.requests
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface HomeProps extends PropsFromRedux { }

const Home: React.FC<HomeProps> = ({
    setUserProfile,
    requests,
    getRequests,
    getDatasourceTypes
}: HomeProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [currentMainView, setCurrentMainView] = React.useState<HomeView>(HomeView.Dashboard);
    const [isUnauthorized, setIsUnauthorized] = React.useState<boolean>(false);
    const { instance, accounts } = useMsal();

    const [mollieTransactionId, setMollieTransactionId] = React.useState("");
    const [mollieTenant, setMollieTenant] = React.useState<ITenant>();

    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const trns = searchParams.get('trns');
        if (trns) {
            setMollieTransactionId(trns);
            setCurrentMainView(HomeView.Subscriptions)
        }
    }, []);

    React.useEffect(() => {
        const asyncWrapper = async () => {
            await updateData();
        }
        asyncWrapper();
    }, []);

    const updateData = async () => {
        setIsLoading(true);
        try {
            let userProfile: IPortalUser = await getCurrentProfile({ accountId: accounts[0]?.homeAccountId, username: accounts[0]?.username });
            if (userProfile) {
                userProfile.tenants = userProfile.tenants.map(tenant => {
                    const advancedFields = (tenant.allowedAdvancedSearchFields as string)?.trim().toLowerCase().split(";") ?? [];
                    const allowedTeams = (tenant.allowedTeams as string)?.trim().toLowerCase().split(";") ?? [];
                    const allowedSiteCollections = (tenant.allowedSiteCollections as string)?.trim().toLowerCase().split(";") ?? [];
                    const hiddenMetadataFields = (tenant.hiddenMetadataFields as string)?.trim().toLowerCase().split(";") ?? [];
                    return ({
                        ...tenant,
                        allowedAdvancedSearchFields: advancedFields,
                        allowedSiteCollections,
                        allowedTeams,
                        hiddenMetadataFields,
                    })
                })
                setUserProfile(userProfile, accounts[0]);
                if (requests.length === 0 && userProfile.isGlobalAdmin) getRequests();
                getDatasourceTypes();
            }
            else {
                setIsUnauthorized(true);
            }
        } catch (e) {
            console.error(e);
        }

        setIsLoading(false);
    };

    const signOut = () => {
        instance.logoutRedirect();
    }

    return isLoading
        ? <Loading label={languageStrings.LoadingPortal} />
        : !isUnauthorized
            ? <React.Fragment>
                <TopNavigator email={accounts[0]?.username} signOut={signOut} />
                <Stack horizontal>
                    <Stack.Item>
                        <NavMenu setSelectedView={setCurrentMainView} />
                    </Stack.Item>
                    {currentMainView === HomeView.Dashboard ?
                        <Stack.Item className={MainViewStyle}>
                            <Dashboard
                                updateData={updateData}
                                browseToSubscription={(tenant: ITenant) => {
                                    setMollieTenant(tenant);
                                    setCurrentMainView(HomeView.Subscriptions);
                                }}
                            />
                        </Stack.Item> : null}
                    {currentMainView === HomeView.Users
                        ? <Stack.Item className={MainViewStyle}>
                            <UsersTable />
                        </Stack.Item> : null}
                    {currentMainView === HomeView.PartnersOverview ?
                        <Stack.Item className={MainViewStyle}>
                            <PartnersOverview />
                        </Stack.Item> : null}
                    {currentMainView === HomeView.PartnersRequests ?
                        <Stack.Item className={MainViewStyle}>
                            <Requests />
                        </Stack.Item> : null}
                    {currentMainView === HomeView.Subscriptions ?
                        <Stack.Item className={MainViewStyle}>
                            <Subscriptions transactionId={mollieTransactionId} defaultTenant={mollieTenant} />
                        </Stack.Item> : null}
                </Stack>
            </React.Fragment>
            : <MessageScreen
                text={languageStrings.UnauthorizedMessage}
                actionButton={
                    <DefaultButton style={{ marginTop: 10 }} onClick={signOut}>
                        {languageStrings.SignInDifferentAccount}
                    </DefaultButton>
                }
            />
}

export default connector(Home);