import { DefaultButton, IButtonStyles, IPanelStyles, IStackStyles, Label, mergeStyles, Panel, PanelType, ScrollablePane, ScrollbarVisibility, Spinner, Stack, StackItem } from "@fluentui/react";
import React from "react";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../utilities/LocalizationModule";
import { FilterSection } from "../../data-structures/interfaces";
import { UserFilterPanelSection } from "../UserFilterPanelSection";
import { RootState } from "../../redux";
import { setFilterSections, setFilterSectionsReset, toggleReload } from "../../redux/modules/filters";
import { LicenseType } from "../../data-structures/enums";


export const scrollStyle = mergeStyles({
    position: "relative",
    height: "calc(100vh - 140px)",
    marginTop: 5,
    marginBottom: 10
});

export const panelActionButtonStyle: Partial<IButtonStyles> = {
    root: {
        marginTop: 20,
    }
}

const panelStyles: Partial<IPanelStyles> = {
    main: {
        overflow: "hidden"
    },
    content: {
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 0
    }
}

const noFiltersStackStyles: Partial<IStackStyles> = {
    root: { width: "100%", height: "100%", margin: 0, padding: 0, overflow: "auto" }
};

const loaderStackStyles: Partial<IStackStyles> = {
    root: { width: "100%", height: "100%", margin: 0, padding: 0, overflow: "auto" }
};

const stackTokens = { childrenGap: 25 };

export interface ICMFileFilterPanelProps {
    closePanel?: () => void;
    panelIsOpen: boolean;
}

export const UserFilterPanel = ({ closePanel, panelIsOpen }: ICMFileFilterPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useDispatch();

    const users = useSelector((state: RootState) => state.user.users);
    const filterSections = useSelector((state: RootState) => state.filter?.filterSections);

    const [filterSelected, setFilterSelected] = React.useState<boolean>(false);
    const [hasFilters, setHasFilters] = React.useState(true);

    React.useEffect(() => {
        if (filterSections.length === 0) {
            const newSections: FilterSection[] = [];

            let uniqueTenants = Array.from(new Set(users.map(item => item.tenantName))).sort();

            const tenantSection: FilterSection = {
                filterField: {
                    realFieldName: languageStrings.Tenant,
                    type: "Text",
                    displayName: languageStrings.Tenant
                },
                items: uniqueTenants.map(tenant => {
                    return {
                        title: tenant,
                        value: tenant,
                        selected: false
                    }
                }),
                showMoreExpanded: false,
                hasSlider: false,
                showSlider: false
            }

            const roleSection: FilterSection = {
                filterField: {
                    realFieldName: languageStrings.Role,
                    type: "Text",
                    displayName: languageStrings.Role
                },
                items: [],
                showMoreExpanded: false,
                hasSlider: false,
                showSlider: false
            }

            const hasTenantAdmins = users.some(x => x.isTenantAdmin);
            const hasPartners = users.some(x => x.isPartner);

            if (hasTenantAdmins || hasPartners) {
                roleSection.items.push({ title: languageStrings.NoRoleAssigned, value: languageStrings.NoRoleAssigned, selected: false })
            }

            if (hasTenantAdmins) {
                roleSection.items.push({ title: languageStrings.TenantAdmin, value: languageStrings.TenantAdmin, selected: false })
            }

            if (hasPartners) {
                roleSection.items.push({ title: languageStrings.Partner, value: languageStrings.Partner, selected: false })
            }

            const licenceTypeSection: FilterSection = {
                filterField: {
                    realFieldName: languageStrings.LicenseType,
                    type: "Text",
                    displayName: languageStrings.LicenseType
                },
                items: [
                    { title: languageStrings.Expired, value: LicenseType.Expired.toString(), selected: false },
                    { title: languageStrings.Evaluation, value: LicenseType.Eval.toString(), selected: false },
                    { title: languageStrings.Paid, value: LicenseType.Paid.toString(), selected: false },
                    { title: languageStrings.Inactive, value: LicenseType.Inactive.toString(), selected: false },
                    { title: languageStrings.Unassigned, value: LicenseType.Unassigned.toString(), selected: false },
                ],
                showMoreExpanded: false,
                hasSlider: false,
                showSlider: false
            }

            const activeSection: FilterSection = {
                filterField: {
                    realFieldName: languageStrings.Active,
                    type: "Text",
                    displayName: languageStrings.Active
                },
                items: [
                    { title: languageStrings.Yes, value: "true", selected: false },
                    { title: languageStrings.No, value: "false", selected: false }
                ],
                showMoreExpanded: false,
                hasSlider: false,
                showSlider: false
            }

            newSections.push(tenantSection);
            newSections.push(roleSection);
            newSections.push(licenceTypeSection);
            newSections.push(createDateSection("dateRegistered", languageStrings.DateRegistered));
            newSections.push(createDateSection("licenseExpiryDate", languageStrings.ExpiryDate));
            newSections.push(createDateSection("lastActivityDate", languageStrings.LastActivity));
            newSections.push(activeSection);

            dispatch(setFilterSections(newSections));
        }
    }, []);

    const createDateSection = (propName: string, fieldName: string) => {
        let uniqueDatesSet = new Set(users.filter(x => fieldName === languageStrings.LicenseExpiryDate ? x.licenseType !== LicenseType.Paid : true).map(item => {
            let date = new Date(item[propName]);
            date.setHours(0, 0, 0, 0);
            return date.toLocaleDateString();
        }));
       
        let uniqueDates = Array.from(uniqueDatesSet)
            .filter(dateString => {
                let date = new Date(dateString);
                return !isNaN(date.getTime()) && date.getFullYear() > 1900;
            })
            .filter(x => x !== "1/1/1")
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        const section: FilterSection = {
            filterField: {
                realFieldName: propName,
                type: "DateTime",
                displayName: fieldName
            },
            //items: [{ title: `(${languageStrings.Empty})`, value: languageStrings.Empty, selected: false }],
            items: [],
            showMoreExpanded: false,
            hasSlider: true,
            showSlider: true
        }

        const uniqueItems = uniqueDates.map(item => {
            return {
                title: item,
                value: item,
                selected: false
            }
        })

        section.items.push(...uniqueItems);

        return section;
    }

    React.useEffect(() => {
        let isSelected = false;
        filterSections.forEach(section => {
            if (!isSelected && !section.daysOffset) {
                section.items.forEach(item => {
                    if (!isSelected && item.selected === true) {
                        isSelected = true;
                    }
                });
            } else {
                isSelected = true;
            }
        });
        setFilterSelected(isSelected);

        if (filterSections.length > 0) {
            let hasFilterItems = false;
            filterSections.forEach(section => {
                if (!hasFilterItems) {
                    if (section.items?.length > 0) {
                        hasFilterItems = true;
                    }
                }
            });
            setHasFilters(hasFilterItems);
        }
    }, [filterSections]);

    const filtersLoader = filterSections.length === 0 && (
        <Stack verticalAlign="center" horizontalAlign="center" styles={loaderStackStyles}>
            <StackItem align="center">
                <Spinner
                    label={languageStrings.LoadingFilters}
                    ariaLive="assertive"
                    labelPosition="bottom"
                />
            </StackItem>
        </Stack>
    );

    return (
        <Panel
            headerText={languageStrings.Filter}
            isOpen={panelIsOpen}
            onDismiss={() => {
                closePanel();
            }}
            isLightDismiss={true}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={'350px'}
            styles={panelStyles}
        >
            <div className={scrollStyle} >
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <div className="xb-content" style={{ marginLeft: 20 }}>
                        {filtersLoader}
                        {!hasFilters && <Stack verticalAlign="center" horizontalAlign="center" styles={noFiltersStackStyles}>
                            <Stack.Item align="center">
                                <Label disabled>{languageStrings.NoFiltersAvailable}</Label>
                            </Stack.Item>
                        </Stack>}
                        {hasFilters && <Stack tokens={stackTokens}>
                            {filterSections.length > 0 && filterSections?.map((section, index) => {
                                return (
                                    <Stack.Item key={index}>
                                        <UserFilterPanelSection
                                            filterSection={section}
                                        />
                                    </Stack.Item>
                                )
                            })}
                        </Stack>}
                    </div>
                </ScrollablePane>
            </div>
            {filterSections.length > 0 && hasFilters && <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }} style={{ paddingRight: 10 }}>
                <DefaultButton
                    styles={panelActionButtonStyle}
                    iconProps={{ iconName: 'Refresh' }}
                    disabled={!filterSelected}
                    onClick={async () => {
                        dispatch(setFilterSectionsReset());
                        dispatch(toggleReload());
                    }}
                    text={languageStrings.Reset}
                />
            </Stack>}
        </Panel>
    )
};