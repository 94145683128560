import ReactDOM from 'react-dom/client';
import './styles/styles.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './utilities/authConfig';
import { store } from './_v2/redux/store';
import { Provider } from 'react-redux';
import { initializeIcons } from '@fluentui/react';

initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();