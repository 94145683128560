export enum HomeView {
    Dashboard = "Dashboard",
    Users = "Users",
    Partners = "Partners",
    PartnersOverview = "Overview",
    PartnersRequests = "Requests",
    DataSources = "DataSources",
    Subscriptions = "Subscriptions",
    Auth = "Auth",
}

export enum LicenseType {
    Expired = 1,
    Paid = 2,
    Eval = 3,
    Unassigned = 4,
    Inactive = 5
}

export enum LandingPage {
    Default = 0,
    SharePoint = 1,
    Teams = 2,
    OneDrive = 3,
    Exchange = 4
}

export enum GraphType {
    Registrations = 1,
    UserActivity = 2
}

export enum MessageType {
    Confirmation,
    Success,
    Fail,
    Reason,
    YesNo
}

export enum PanelReturnType {
    Remove,
    Add,
    CreateTenant,
    AssignAdmin,
    RemoveAdmin,
    AddSearchField,
    RemoveSearchField,
    RemovePartnerUser,
    AddPartnerUser
}

export enum TenantListSections {
    AdvancedSearch,
    MetaData,
    Sites,
    Teams
}

export enum DatasourceTypeEnum {
    SQLServer = 1,
    UniveCRM = 2,
    SimplicateOrganization = 3,
    SimplicateProject = 4,
    SimplicateEmployee = 5,
    SharePoint = 6,
    Internal = 7,
    Exchange = 8
}

export enum SubscriptionIntervalEnum {
    OnceOff = 0,
    Monthly = 1,
    Yearly = 2
}

export enum SubscriptionTierEnum {
    Trial = "Trial",
    Paid = "Paid"
}

export enum SubscriptionStatusEnum {
    Pending = "pending",
    Active = "active",
    Canceled = "canceled",
    Suspended = "suspended",
    Completed = "completed",
}