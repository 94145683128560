import React, { useMemo, useEffect } from "react"
import { IDocumentLibrary, ISite } from "../data-structures/interfaces"
import { DetailsList, ScrollablePane, ScrollbarVisibility, IColumn, SelectionMode, Selection, IObjectWithKey, PersonaSize, Persona, mergeStyles, Toggle, Stack, CommandBarButton, TextField } from '@fluentui/react';
import * as ListStyles from "../styles/ListStyles"
import { LanguageContext } from '../utilities/LocalizationModule';
import { useCallback } from "react";


interface LibrariesGridProps {
    items?: IDocumentLibrary[],
    setSelectedLibs: (titles: string[]) => void,
    filterText: string,
    handleToggle: (index: number) => void,
    handleHiddenToggle: (index: number) => void,
    setEditMode: (index: number) => void,
    editMode: number,
    onChangeFieldName: (index: number, newValue: string) => void,
    hasError: boolean
}

const LibrariesGrid: React.FunctionComponent<LibrariesGridProps> = ({
    items,
    setSelectedLibs,
    filterText,
    handleToggle,
    handleHiddenToggle,
    editMode,
    setEditMode,
    onChangeFieldName,
    hasError
}) => {
    const languageStrings = React.useContext(LanguageContext);

    const _selection = useMemo(() => new Selection({
        onSelectionChanged: () => handleSelection()
    }), []);

    const handleSelection = () => {
        const selected: IObjectWithKey[] = _selection.getSelection();
        if (selected && selected.length > 0) {
            const selectedTitles = selected.map(value => {
                return String(value["title"])
            });
            setSelectedLibs(selectedTitles);
        }
        else {
            setSelectedLibs([]);
        }
    }

    const columns: IColumn[] = useMemo(() => {
        return [
            {
                key: 'title',
                name: languageStrings.Title,
                fieldName: 'title',
                data: "string",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
            },
            {
                key: 'autoFolderCreationEnabled',
                name: languageStrings.AutoFolderCreation,
                fieldName: 'autoFolderCreationEnabled',
                data: "boolean",
                minWidth: 100,
                maxWidth: 130,
                isResizable: true,
            },
            {
                key: 'hidden',
                name: languageStrings.Hidden,
                fieldName: 'hidden',
                data: "boolean",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
            },
            {
                key: 'edit',
                name: "",
                fieldName: 'edit',
                minWidth: 30,
                maxWidth: 30,
                isResizable: true,
            },
        ]
    }, [editMode]);

    const renderItemColumn = (item: IDocumentLibrary, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof ISite] as string;
        switch (column.key) {
            case "title":
                return (editMode !== index ?
                    <Persona text={item.title} size={PersonaSize.size40} />
                    : <Stack verticalAlign="center">
                        <TextField id={`textField-${index}`} placeholder={item.title} />
                    </Stack>
                )
            case "autoFolderCreationEnabled":
                return (
                    <Stack style={{ height: "100%", width: "100%" }} verticalAlign="center" horizontalAlign="center">
                        <Toggle
                            checked={item.autoFolderCreationEnabled}
                            onClick={() => handleToggle(index)}
                            styles={{
                                root: {
                                    margin: 0
                                }
                            }}
                        />
                    </Stack>
                )
            case "hidden":
                return (
                    <Stack style={{ height: "100%", width: "100%" }} verticalAlign="center" horizontalAlign="center">
                        <Toggle
                            checked={item.hidden}
                            onClick={() => handleHiddenToggle(index)}
                            styles={{
                                root: {
                                    margin: 0
                                }
                            }}
                        />
                    </Stack>
                )                
            case "edit":
                return (
                    <Stack style={{ height: "100%", width: "100%" }} verticalAlign="center" horizontalAlign="start">
                        <CommandBarButton
                            iconProps={{
                                iconName: editMode !== index ? "edit" : "save",
                            }}
                            styles={{
                                root: {
                                    backgroundColor: 'transparent',
                                    padding: 10
                                }
                            }}
                            onClick={() => {
                                if (editMode !== index) {
                                    setEditMode(index);
                                } else {
                                    const newValue = document.getElementById(`textField-${index}`).getAttribute("value");                                   
                                    onChangeFieldName(index, newValue);
                                    setEditMode(-1);
                                }
                            }}
                        />
                    </Stack>
                )
            default:
                return <span>{fieldContent}</span>;
        }
    }

    const filteredSites = useMemo(() => {
        if (items && items.length > 0) {
            return (
                items.filter(site => site?.title.toLowerCase().includes(filterText))
            )
        } else {
            return [];
        }
    }, [items, filterText])

    return (
        <div className={ListStyles.documentLibraryGridScrollStyle(hasError)}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    items={filteredSites}
                    onRenderItemColumn={renderItemColumn}
                    columns={columns}
                    selectionMode={SelectionMode.multiple}
                    selection={_selection}
                    checkboxCellClassName={mergeStyles({
                        display: "flex",
                        alignItems: "center"
                    })}
                //onRenderRow={(props) => console.log(props)}
                />
            </ScrollablePane>
        </div>
    )
}

export default LibrariesGrid