import React from 'react'
import Loading from '../Loading'
import { GetAuthUrl, geExactAuthToken } from '../../utilities/helpers/ApiHelper';
import * as LZString from "lz-string";
import { StorageHelper } from '../../utilities/helpers/StorageHelper';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Label } from '@fluentui/react';

const Auth = () => {
    const languageStrings = React.useContext(LanguageContext);

    const [error, setError] = React.useState<string>();

    const urlParams = new URLSearchParams(window.location.search);

    const ds = urlParams.get("ds");
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    React.useEffect(() => {
        if (code && state) {
            (async () => {
                try {
                    const token = await geExactAuthToken(await StorageHelper.getItemAsync("AuthApiEndpoint", true), code, state);
                    if (window.opener && code) {
                        // Send the code back to the main React app
                        window.opener.postMessage({
                            type: 'authCode',
                            code: token
                        }, window.location.origin);

                        // Close the popup
                        window.close();
                    }
                    else {
                        setError(languageStrings.AuthFailed);
                    }
                } catch (error) {
                    setError(languageStrings.AuthFailed);
                }
            })();
        } else if (ds) {
            (async () => {
                try {
                    // If code and state are not in the URL, redirect to authUrl
                    const datasource = JSON.parse(LZString.decompressFromEncodedURIComponent(ds));
                    const authReponse = await GetAuthUrl(datasource);
                    if (authReponse) {
                        await StorageHelper.setItemAsync("AuthApiEndpoint", authReponse.authApiEndpoint);
                        const authUrl = new URL(authReponse.authUrl);
                        window.location.replace(authUrl);
                    }
                    else {
                        setError(languageStrings.AuthFailed);
                    }
                } catch (error) {
                    setError(languageStrings.AuthFailed);
                }
            })();
        }
        else {
            setError(languageStrings.AuthFailed);
        }
    }, [ds, code, state]);

    return !error
        ? <Loading label={languageStrings.AuthProcessing} />
        : <div className="xb-loader-container"><Label>{error}</Label></div>;
}

export default Auth;