import { IUser } from "../models";
import { typedAction } from "../helpers";
import { SET_USERS, SET_USER_PROFILE, SET_TENANTS, SET_ACTIVITIES, SET_LOADING, SET_SITE_LIB, SET_TENANT_SITE, SET_TENANT_DATASOURCE } from "../actionTypes";
import { IAdminStatusUpdate, IBoundField, IBulkUpdate, IDatasource, IDocumentLibrary, IPortalPartner, IPortalUser, ISite, ITenant, ITenantPartner, ITenantUser, ITenantUserActivity } from "../../data-structures/interfaces";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { AccountIdentifiers } from "@azure/msal-react";
import { updateTenant as updateTenantAPI, removeTenant, saveUserChanges, getCurrentProfile, bulkUpdates as bulkUpdateUsersAPI, removeTenantsAPI, assignTenantsAPI, createPartner as createPartnerAPI, updatePartner as editPartnerAPI, deletePartner as deletePartnerAPI, deleteTenant as deleteTenantAPI, addTenantAPI, updateAdminStatus as updateAdminStatusAPI, getTenantSites as getTenantSitesAPI, deleteSite as deleteSiteAPI, addTenantSites, getSiteLibraries as getSiteLibrariesAPI, addSiteLibrary as addSiteLibraryAPI, deleteLibrary as deleteLibrariesAPI, updateSite as updateSiteAPI } from "../../utilities/helpers/ApiHelper"
import moment from "moment";

const initialState: IUser = {
    account: undefined,
    isGlobalAdmin: false,
    isTenantAdmin: false,
    isPartner: false,
    users: [],
    id: null,
    tenants: [],
    partners: [],
    userActivities: [],
    isUserLoading: false,
};

export const setUsers = (users: ITenantUser[]) => {
    try {
        return typedAction(SET_USERS, users);
    }
    catch (e) {
        console.error(e);
        return null;
    }
};

export const setLoading = (loading: boolean) => {
    try {
        return typedAction(SET_LOADING, loading);
    }
    catch (e) {
        console.error(e);
        return null;
    }
};

export const setTenants = (tenants: ITenant[]) => {
    try {
        return typedAction(SET_TENANTS, tenants);
    } catch (e) {
        console.error(e)
        return null;
    }
};

export const setTenantSites = (tenantId: number, sites: ISite[]) => {
    try {
        return typedAction(SET_TENANT_SITE, { tenantId, sites });
    } catch (e) {
        console.error(e);
        return null;
    }
}

export const setSiteLibrary = (tenantId: number, siteDisplayName: string, library: IDocumentLibrary[]) => {
    try {
        return typedAction(SET_SITE_LIB, { siteDisplayName, library, tenantId });
    } catch (e) {
        console.error(e);
        return null;
    }

}

export const setTenantDatasource = (tenantId: number, datasources: IDatasource[]) => {
    try {
        return typedAction(SET_TENANT_DATASOURCE, { tenantId, datasources })
    } catch (e) {
        console.error(e);
        return null;
    }
}

export const setActivities = (activities: ITenantUserActivity[]) => {
    try {
        return typedAction(SET_ACTIVITIES, activities);
    } catch (e) {
        console.error(e)
        return null;
    }
};

export const setUserProfile = (profile: IPortalUser, account: AccountIdentifiers) => {
    try {
        const newUsers: ITenantUser[] = profile.users.map<ITenantUser>(u => ({
            ...u,
            licenseExpiryDate: moment.utc(u.licenseExpiryDate).local().toDate(),
            dateRegistered: moment.utc(u.dateRegistered).local().toDate(),
            lastActivityDate: moment.utc(u.lastActivityDate).local().toDate(),
            licenseType: u.licenseType ? u.licenseType : null,
            tenantName: u.tenantName ? u.tenantName : "",
        }));
        profile.users = newUsers;
        return typedAction(SET_USER_PROFILE, { profile, account });
    } catch (e) {
        console.error(e)
        return null;
    }
};

export const updateBoundFields = (tenantId: number, datasource: IDatasource, boundFields: IBoundField[]): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const datasources: IDatasource[] = getState().user.tenants.find(t => t.id === tenantId).datasources.map(d => {
        return ({
            ...d
        })
    });
    const ds = datasources.find(d => d.id === datasource.id);
    ds.boundFields = boundFields; //updates the datasources array too.
    try {
        dispatch(setTenantDatasource(tenantId, datasources));

    } catch (e) {
        console.error(e);
    }
};

export const updateTenant = (tenant: ITenant): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const existingTenants: ITenant[] = [...getState().user.tenants] ?? [];
    const updatedUsers: ITenantUser[] = [...getState().user.users].map(u => u.tenantId === tenant.tenantId ?
        {
            ...u,
            tenantName: tenant.friendlyName,
            enableOneDrive: tenant.enableOneDrive,
            enableSharePoint: tenant.enableSharePoint,
            enableTeams: tenant.enableTeams,
            saveOnSend: tenant.saveOnSend,
        } : {
            ...u
        }) ?? [];
    try {
        existingTenants[existingTenants.findIndex(t => t.id === tenant.id)] = {
            ...tenant,
            allowedAdvancedSearchFields: tenant.showAllAdvancedSearchFields ? null : tenant.allowedAdvancedSearchFields?.length > 0 ? tenant.allowedAdvancedSearchFields : null,
            allowedSiteCollections: tenant.showAllSites ? null : tenant.allowedSiteCollections?.length > 0 ? tenant.allowedSiteCollections : null,
            allowedTeams: tenant.showAllTeams ? null : tenant.allowedTeams?.length > 0 ? tenant.allowedTeams : null,
            hiddenMetadataFields: tenant.showAllMetaDataFields ? null : tenant.hiddenMetadataFields?.length > 0 ? tenant.hiddenMetadataFields : null,
        };
        dispatch(setTenants(existingTenants));
        dispatch(setUsers(updatedUsers));

        //these are trimmed in orderedListField
        const allowedAdvancedSearchFields = tenant.showAllAdvancedSearchFields ? null : (tenant.allowedAdvancedSearchFields as string[]).length > 0 ? (tenant.allowedAdvancedSearchFields as string[]).join(";") : null;

        const allowedTeams = tenant.showAllTeams ? null : (tenant.allowedTeams as string[]).length > 0 ? (tenant.allowedTeams as string[]).join(";") : null;

        const allowedSiteCollections = tenant.showAllSites ? null : (tenant.allowedSiteCollections as string[]).length > 0 ? (tenant.allowedSiteCollections as string[]).join(";") : null;

        const hiddenMetadataFields = tenant.showAllMetaDataFields ? null : (tenant.hiddenMetadataFields as string[]).length > 0 ? (tenant.hiddenMetadataFields as string[]).join(";") : null;
        updateTenantAPI({
            ...tenant,
            allowedAdvancedSearchFields,
            allowedTeams,
            allowedSiteCollections,
            hiddenMetadataFields
        }); //api call

    } catch (e) {
        console.error(e);
    }
};

export const assignTenant = (tenant: ITenant): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const existingTenants: ITenant[] = [...getState().user.tenants] ?? [];

    try {
        existingTenants[existingTenants.findIndex(t => t.id === tenant.id)] = tenant;
        dispatch(setTenants(existingTenants));

    } catch (e) {
        console.error(e);
    }
};

export const deleteTenant = (tenant: ITenant): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const userState = getState().user;
    const remainingTenants: ITenant[] = [...userState.tenants].filter(t => t.id !== tenant.id) ?? [];
    const remainingUsers: ITenantUser[] = [...userState.users].filter(u => u.tenantId !== tenant.tenantId) ?? [];
    dispatch(setLoading(true));
    try {
        dispatch(setTenants(remainingTenants));
        dispatch(setUsers(remainingUsers));
        await removeTenant(tenant.partner?.id, tenant); //api call
        const userProfile: IPortalUser = await getCurrentProfile({ accountId: userState.account.homeAccountId, username: userState.account.username }); //api call
        //setUsers
        dispatch(setActivities(userProfile.userActivities)); //set the entire profile

    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return true
};

export const updateUser = (user: ITenantUser, skipApiCall?: boolean): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const existingUsers: ITenantUser[] = [...getState().user.users] ?? [];
    try {
        existingUsers[existingUsers.findIndex(u => u.id === user.id)] = user;

        if (!skipApiCall) {
            saveUserChanges(user);//api call
        }
        
        dispatch(setUsers(existingUsers));
    } catch (e) {
        console.error(e);
    }
};

export const bulkUpdateUsers = (bulkUpdate: IBulkUpdate): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const updatedUsers: ITenantUser[] = [...getState().user.users].map(u => bulkUpdate.userIds.some(uid => uid === u.id) ?
        {
            ...u,
            licenseExpiryDate: bulkUpdate.userIds.includes(u.id) && bulkUpdate.licenseExpiryDate ? bulkUpdate.licenseExpiryDate : u.licenseExpiryDate,
            licenseType: bulkUpdate.userIds.includes(u.id) && bulkUpdate.licenseType ? bulkUpdate.licenseType : u.licenseType,
            enableOneDrive: bulkUpdate.enableOneDrive,
            enableSharePoint: bulkUpdate.enableSharePoint,
            enableTeams: bulkUpdate.enableTeams,
            enableApprovals: bulkUpdate.enableApprovals,
            saveOnSend: bulkUpdate.saveOnSend,
            autoSelectAttachments: bulkUpdate.autoSelectAttachments,
            autoRemoveMailItemAttachments: bulkUpdate.autoRemoveMailItemAttachments,
            convertEmailToPDF: bulkUpdate.convertEmailToPDF,
            isActivated: bulkUpdate.isActivated,
            landingPage: bulkUpdate.landingPage
        } : {
            ...u
        }) ?? [];
    try {
        bulkUpdateUsersAPI(bulkUpdate);//api call
        dispatch(setUsers(updatedUsers));
    } catch (e) {
        console.error(e);
    }
};

export const removeTenants = (tenants: ITenant[], partner: ITenantPartner): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const existingTenants: ITenant[] = [...getState().user.tenants].map<ITenant>(t => {
        return ({
            ...t,
            partner: tenants.map(te => te.id).includes(t.id) ? null : t.partner
        })
    })
        ?? [];

    try {
        dispatch(setTenants(existingTenants))
        //removeTenantsAPI(partner.id, tenants.map(t => t.id));//api call

    } catch (e) {
        console.error(e);
    }
};

//This is for adding tenants to a partner
export const assignTenants = (tenants: ITenant[], partner: ITenantPartner): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const existingTenants: ITenant[] = [...getState().user.tenants].map<ITenant>(t => {
        return ({
            ...t,
            partner: tenants.map(te => te.id).includes(t.id) ? partner : t.partner
        })
    })
        ?? [];
    try {
        dispatch(setTenants(existingTenants))
        //assignTenantsAPI(partner.id, tenants.map(t => t.id));//api call
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false))
};

export const addTenant = (tenant: ITenant, apiCall: boolean): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const existingTenants: ITenant[] = [...getState().user.tenants] ?? [];
    dispatch(setLoading(true))
    try {
        if (apiCall) {
            const newTenant: ITenant = (await addTenantAPI(tenant)).tenant; //api call
            existingTenants.push(newTenant)
        } else {
            existingTenants.push(tenant)
        }

        dispatch(setTenants(existingTenants));
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false))
};

export const updateAdminStatus = (adminStatus: IAdminStatusUpdate): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const existingUsers: ITenantUser[] = [...getState().user.users].map(u => ({
        ...u,
        isTenantAdmin: adminStatus.UserIds.includes(u.id) ? adminStatus.isAdmin : u.isTenantAdmin,
    })) ?? [];

    try {
        await updateAdminStatusAPI(adminStatus); //api call
        dispatch(setUsers(existingUsers));
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false))
};

// export const createPartner = (partner: IPortalPartner): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
//     const existingUsers: ITenantUser[] = [...getState().user.users] ?? [];
//     dispatch(setLoading(true));
//     try {

//         //TODO
//         const newPartner = await createPartnerAPI(partner);//api call

//         // if (newPartner) {
//         //     existingUsers.push({
//         //         ...newPartner,
//         //         licenseExpiryDate: moment.utc(newPartner.licenseExpiryDate).local().toDate(),
//         //         dateRegistered: moment.utc(newPartner.dateRegistered).local().toDate(),
//         //         lastActivityDate: moment.utc(newPartner.lastActivityDate).local().toDate(),
//         //     })
//         // };
//         //dispatch(setUsers(existingUsers));
//         return newPartner;

//     } catch (e) {
//         console.error(e);
//         return null;
//     }
// };

export const editPartner = (partner: ITenantUser): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const existingUsers: ITenantUser[] = [...getState().user.users] ?? [];
    const index = existingUsers.findIndex(u => u.id === partner.id)
    try {
        dispatch(setLoading(true))
        
        //await editPartnerAPI(partner);//api call //TODO
        if (partner) { existingUsers[index] = partner } else { console.error("Something went wrong!") }
        dispatch(setUsers(existingUsers));
        dispatch(setLoading(false))
    } catch (e) {
        console.error(e);
    }
};

export const deletePartner = (partner: ITenantUser): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const existingUsers: ITenantUser[] = [...getState().user.users].filter(u => u.id !== partner.id) ?? [];

    try {
        dispatch(setLoading(true))
        await deletePartnerAPI(partner.id);//api call
        dispatch(setUsers(existingUsers));
        dispatch(setLoading(false))
    } catch (e) {
        console.error(e);
    }
};

export const deleteTenantGlobal = (tenantId: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //called when global admin deletes a tenant that has 0 users
    const userState = getState().user;
    const remainingTenants: ITenant[] = [...userState.tenants].filter(t => t.id !== tenantId) ?? [];
    dispatch(setLoading(true));
    try {
        dispatch(setTenants(remainingTenants));
        await deleteTenantAPI(tenantId); //api call
        const userProfile: IPortalUser = await getCurrentProfile({ accountId: userState.account.homeAccountId, username: userState.account.username }); //api call
        //setUsers
        dispatch(setActivities(userProfile.userActivities)); //set the entire profile

    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return true
};

export const getTenantSites = (tenantId: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const tenants = getState().user.tenants;
    const sites: ISite[] = await getTenantSitesAPI(tenantId);
    const updatedTenants: ITenant[] = tenants.map(t => {
        return ({
            ...t,
            sites: t.id === tenantId ? sites : t.sites,
        })
    })
    dispatch(setTenants(updatedTenants));
}

export const deleteSites = (siteIds: number[], tenantId: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const tenants = getState().user.tenants;
    await deleteSiteAPI(siteIds);
    const updatedTenants: ITenant[] = tenants.map(t => {
        if (t.id !== tenantId) {
            return t;
        }
        return ({
            ...t,
            sites: t.sites.filter(site => !siteIds.includes(site.id))
        })
    })
    dispatch(setTenants(updatedTenants));
}

export const addSites = (sites: ISite[], tenantId: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const tenants = getState().user.tenants;
    let newSites: ISite[] = await addTenantSites(tenantId, sites);

    newSites = newSites.map<ISite>(site => {
        return ({
            ...site,
            documentLibraries: null,
        })
    })
    const updatedTenants: ITenant[] = tenants.map(t => {
        if (t.id !== tenantId) {
            return t;
        }
        return ({
            ...t,
            sites: t.sites.concat(newSites)
        })
    })
    dispatch(setTenants(updatedTenants));
}



export const getSiteLibraries = (siteId: number, tenantId: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const tenants = getState().user.tenants;
    const sites = tenants.find(t => t.id === tenantId).sites;
    const libraries: IDocumentLibrary[] = await getSiteLibrariesAPI(siteId);
    const updatedSites: ISite[] = sites.map(site => {
        return ({
            ...site,
            documentLibraries: site.id === siteId ? libraries : site.documentLibraries,
        })
    })
    const updatedTenants = tenants.map(t => {
        return ({
            ...t,
            sites: t.id === tenantId ? updatedSites : t.sites,
        })
    })
    dispatch(setTenants(updatedTenants));
}

export const deleteSiteLibraries = (tenantId: number, siteId: number, libraryIds: number[]): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const tenants = getState().user.tenants;
    const sites = tenants.find(t => t.id === tenantId).sites;
    const updatedSites: ISite[] = [...sites].map(site => {
        if (site.id === siteId) {
            return ({
                ...site,
                documentLibraries: site.documentLibraries.filter(lib => !libraryIds.includes(lib.id)),
            })
        } else {
            return site;
        }
    })

    const updatedTenants = [...tenants].map(t => {
        return ({
            ...t,
            sites: t.id === tenantId ? updatedSites : t.sites,
        })
    })
    dispatch(setTenants(updatedTenants));
    await deleteLibrariesAPI(libraryIds);
}

export const addSiteLibrary = (tenantId: number, siteId: number, newLib: IDocumentLibrary): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const tenants = getState().user.tenants;
    const site = tenants.find(t => t.id === tenantId).sites.find(s => s.id === siteId);
    const newLibAdded: IDocumentLibrary[] = await addSiteLibraryAPI(siteId, [newLib]);

    dispatch(setSiteLibrary(tenantId, site.displayName, site.documentLibraries.concat(newLibAdded)));
}

export const updateSiteName = (tenantId: number, siteId: number, name: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const tenants = getState().user.tenants;
    const sites = tenants.find(t => t.id === tenantId).sites;
    const site: ISite = {
        ...sites.find(s => s.id === siteId),
        displayName: name,
    };
    const updatedSites = sites.map(s => {
        if (s.id === siteId) return site;
        return s;
    })
    updateSiteAPI([site]);
    dispatch(setTenantSites(tenantId, updatedSites));
}

type UserAction = ReturnType<typeof setUserProfile | typeof setUsers | typeof setTenants | typeof setActivities | typeof setLoading | typeof setSiteLibrary | typeof setTenantSites | typeof setTenantDatasource>;

export function userReducer(state = initialState, action: UserAction): IUser {
    switch (action.type) {
        case SET_USER_PROFILE:
            return {
                id: action.payload.profile.id,
                account: action.payload.account,
                users: action.payload.profile.users,
                isGlobalAdmin: action.payload.profile.isGlobalAdmin,
                // isGlobalAdmin: false,
                isTenantAdmin: action.payload.profile.isTenantAdmin,
                isPartner: action.payload.profile.isPartner,
                tenants: action.payload.profile.tenants,
                partners: action.payload.profile.partners,
                userActivities: action.payload.profile.userActivities,
                isUserLoading: false,
            };
        case SET_USERS:
            try {
                return { ...state, users: action.payload };
            }
            catch (e) {
                console.error(e);
                return { ...state }
            }
        case SET_TENANTS:
            return { ...state, tenants: action.payload }
        case SET_ACTIVITIES:
            return { ...state, userActivities: action.payload }
        case SET_LOADING:
            return { ...state, isUserLoading: action.payload }
        case SET_TENANT_SITE:
            const { sites: newSites, tenantId: tId } = action.payload;
            const tenants = { ...state }.tenants.map(t => {
                if (t.id === tId) return { ...t, sites: newSites }
                else return t
            });
            return { ...state, tenants: tenants };
        case SET_SITE_LIB:
            const { siteDisplayName, library, tenantId } = action.payload;
            const tenantSites = { ...state }.tenants.find(t => t.id === tenantId).sites;
            const updatedSites: ISite[] = tenantSites.map(s => {
                if (s.displayName === siteDisplayName) {
                    return ({
                        ...s,
                        documentLibraries: library,
                    })
                } else {
                    return s;
                }
            });
            const updatedTenant: ITenant[] = { ...state }.tenants.map(t => {
                if (t.id === tenantId) {
                    return {
                        ...t,
                        sites: updatedSites
                    }
                } else {
                    return t
                }
            })
            return { ...state, tenants: updatedTenant };

        case SET_TENANT_DATASOURCE:
            let oldTenants: ITenant[] = state.tenants.map(t => {
                return ({
                    ...t
                })
            }); //make deep copy one level deep to not change state
            const tenantToUpdate = oldTenants?.find(t => t.id === action.payload.tenantId);
            tenantToUpdate.datasources = action.payload.datasources; //works because of js reference to arrays
            return { ...state, tenants: oldTenants };
        default:
            return state;
    }
}

