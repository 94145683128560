
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Label, PanelType, Stack, TextField, ILabelStyles, FontSizes, Toggle, Dropdown, IDropdownOption, CommandBarButton, PrimaryButton, DefaultButton, MessageBar, MessageBarType } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { setTenantDatasource, updateBoundFields } from '../../redux/modules/user';
import { IBoundField, IDatasource, IDatasourceAuthDto, ITenant } from '../../data-structures/interfaces';
import { createNewBoundField, deleteBoundField, updateBoundFields as updateBoundFieldsAPI, editDatasource, resetDatasourceCache } from '../../utilities/helpers/ApiHelper';
import InfoBox from '../InfoBox';
import { panelActionButtonStyle, panelCommandButtonStyle } from '../../styles/PanelStyle';
import { DatasourceTypeEnum } from '../../data-structures/enums';
import * as LZString from "lz-string";

const fieldsLabelStyle: Partial<ILabelStyles> = {
    root: {
        fontSize: FontSizes.mediumPlus,
        width: 150,
        marginBottom: 5,
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.user.isUserLoading,
        dsTypes: state.datasource.types,
    };
};

const mapDispatchToProps = {
    updateBoundFields,
    setTenantDatasource
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DataSourcePanelProps extends PropsFromRedux {
    isOpen: boolean,
    dismissPanel: () => void,
    datasource: IDatasource,
    tenant: ITenant,
    setDatasource: (newDatasource: IDatasource) => void,
    structure: string[];
    datasourceUpdated: (updatedDatasource: IDatasource) => void
};

const defaultBoundField: IBoundField = {
    datasourceField: "",
    id: null,
    datasourceId: -1,
    name: "",
    displayAsDropdown: false,
    multiSelect: false,
}

const EditDatasourcePanel: React.FC<DataSourcePanelProps> = ({
    isOpen,
    dismissPanel,
    datasource,
    updateBoundFields,
    tenant,
    setDatasource,
    structure,
    datasourceUpdated,
    dsTypes
}: DataSourcePanelProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [addMode, setAddMode] = React.useState<boolean>(false);
    const [newBoundField, setNewBoundField] = React.useState<IBoundField>({
        ...defaultBoundField,
    });
    const [originalFields, setOriginalFields] = React.useState<IBoundField[]>([]);
    const [originalDatasource, setOriginalDatasource] = React.useState<IDatasource>();
    const [resetCacheIconName, setResetCacheIconName] = React.useState('Refresh');
    const [dataSourceFieldNameError, setDataSourceFieldNameError] = React.useState(false);
    const [authTokenChanged, setAuthTokenChanged] = React.useState(false);
    const [addDataSourceFieldError, setAddDataSourceFieldError] = React.useState("");

    React.useEffect(() => {
        if (datasource) {
            setNewBoundField({
                ...newBoundField,
                datasourceId: datasource.id,
            })
        }
    }, [datasource]);

    React.useEffect(() => {
        if (datasource) {
            setOriginalFields(datasource.boundFields);
            setOriginalDatasource(datasource);
        }
    }, [structure]);

    const datasourceFieldsOptions: IDropdownOption[] = React.useMemo(() => {
        const options: IDropdownOption[] = structure
            .filter(field => {
                const ds = datasource?.boundFields?.find(f => f.datasourceField.trim().toLowerCase() === field.trim().toLowerCase());
                return ds?.name == null;
            })
            .map(field => {
                return ({
                    text: field,
                    key: field,
                })
            });

        return options;
    }, [structure, datasource]);

    React.useEffect(() => {
        function handleMessage(event) {
            if (event.origin !== window.location.origin) return;

            const receivedData = event.data;

            if (receivedData.type === 'authCode') {
                datasourceUpdated({
                    ...datasource,
                    authTokenData: receivedData.code
                });
                setAuthTokenChanged(true);
            }
        }

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [datasource, setDatasource]);

    const deleteField = async (id: number) => {
        const res = await deleteBoundField(id);
        if (res === 200) {
            setOriginalFields(datasource.boundFields.filter(bf => bf.id != id));
            updateBoundFields(tenant.id, datasource, datasource.boundFields.filter(bf => bf.id != id));
        }
    }

    const boundFields = React.useMemo(() => {
        return (structure?.length > 0
            ? structure.filter(field => {
                const ds = datasource?.boundFields?.find(f => f.datasourceField.trim().toLowerCase() === field.trim().toLowerCase());
                return ds?.name != null;
            })
            : datasource?.boundFields?.map(f => f.datasourceField))
            ?.map((field, index) => {
                const ds = datasource.boundFields.find(f => f.datasourceField.trim().toLowerCase() === field.trim().toLowerCase());
                return (
                    <Stack key={`field${index}`}>
                        <Stack horizontal horizontalAlign="space-between">
                            <Label styles={fieldsLabelStyle}>
                                {field}
                            </Label>
                            <CommandBarButton
                                iconProps={{ iconName: "delete" }}
                                onClick={() => deleteField(ds?.id)}
                            />
                        </Stack>
                        <TextField
                            value={ds?.name ?? ""}
                            placeholder={languageStrings.EnterSharepointColumn}
                            style={{
                                width: 250
                            }}
                            onChange={(_, nv) => {
                                updateBoundFieldProperty("name", nv, field);
                            }}
                        />
                        <Stack horizontal horizontalAlign="space-between">
                            <Toggle
                                label={languageStrings.Multiselect}
                                checked={ds.multiSelect}
                                onText={languageStrings.Yes}
                                offText={languageStrings.No}
                                onChange={(_, nv) => {
                                    updateBoundFieldProperty("multiSelect", nv, field);
                                }}
                            />
                            <Toggle
                                label={languageStrings.DisplayAsDropDown}
                                checked={ds.displayAsDropdown}
                                onText={languageStrings.Yes}
                                offText={languageStrings.No}
                                onChange={(_, nv) => {
                                    updateBoundFieldProperty("displayAsDropdown", nv, field);
                                }}
                            />
                        </Stack>
                    </Stack>
                )
            });
    }, [structure, datasource])

    const updateBoundFieldProperty = (property: string, newValue: string | boolean, field: string) => {
        const updatedBoundFields: IBoundField[] = datasource.boundFields.map(boundField => {
            if (boundField.datasourceField.trim().toLowerCase() === field.trim().toLowerCase()) {
                switch (property) {
                    case "name":
                        return ({
                            ...boundField,
                            name: newValue as string
                        });
                    case "displayAsDropdown":
                        return ({
                            ...boundField,
                            displayAsDropdown: newValue as boolean
                        });
                    case "multiSelect":
                        return ({
                            ...boundField,
                            multiSelect: newValue as boolean
                        })
                    default:
                        break;
                }
            }
            return ({
                ...boundField
            })
        })
        setDatasource({
            ...datasource,
            boundFields: updatedBoundFields
        })
    }

    const handleAdd = async () => { //Add clicked
        if (datasource?.boundFields.some(x => x.datasourceField === newBoundField?.datasourceField)) {
            setDataSourceFieldNameError(true);
            return;
        }

        setDataSourceFieldNameError(false);
        //add new datasource
        const newField = await createBoundFieldWithRetry(newBoundField);

        if (newField) {
            updateBoundFields(tenant?.id, datasource, datasource?.boundFields.concat(newField));
            setOriginalFields(datasource?.boundFields?.concat(newField));
            setAddMode(false);
        }
    }

    const createBoundFieldWithRetry = async (newBoundField: IBoundField, maxRetries = 3) => {
        let retries = 0;
        let newField;

        while (retries < maxRetries) {
            try {
                newField = await createNewBoundField(newBoundField);
                if (newField) {
                    return newField;
                }
            } catch (error) {
                retries++;
                if (retries === maxRetries) {
                    setAddDataSourceFieldError(languageStrings.ErrorAddingDatasourceMappedField);
                }
            }
        }
    }

    const handleDone = async () => {
        //Done clicked
        //save everything, then dismiss.
        if (!isDoneDisabled) {
            const updatedBFs: IBoundField[] = datasource.boundFields.map((field, index) => {
                if (JSON.stringify(field) !== JSON.stringify(originalFields[index])) {
                    const formattedCSV = field.name.split(',').map(s => s.trim()).filter(s => !!s).join(',');
                    const tmpField: IBoundField = {
                        ...field,
                        name: formattedCSV,
                    }
                    return tmpField;
                }
                return field;
            })
            const res = await updateBoundFieldsAPI(updatedBFs);
            if (res === 200) {
                updateBoundFields(tenant.id, datasource, updatedBFs);
            }
            setOriginalFields(datasource.boundFields);
        }
        setAddMode(false);
        setAddDataSourceFieldError("");
        dismissPanel();
    }

    const handleCancel = () => {
        //reset the bound fields to their original values
        setAddMode(false);
        setAddDataSourceFieldError("");
        setNewBoundField({
            ...defaultBoundField,
            datasourceId: datasource.id,
        });
        setDatasource({
            ...datasource,
            boundFields: originalFields,
        });

        dismissPanel();
    }

    const isAddDisabled: boolean = React.useMemo(() => {
        if (!newBoundField || !newBoundField.name || !newBoundField.datasourceField) {
            return true;
        }
        return false;
    }, [newBoundField, dataSourceFieldNameError]);

    const isDoneDisabled: boolean = React.useMemo(() => {
        const str1 = JSON.stringify(datasource?.boundFields ?? {});
        const str2 = JSON.stringify(originalFields);
        return str1 === str2 || !str1 || !str2 || str1?.length === 0 || str2?.length === 0;
    }, [datasource, originalFields])

    const isSaveDisabled: boolean = React.useMemo(() => {
        if (authTokenChanged) {
            return false
        }
        const str1 = JSON.stringify((datasource?.authKey ?? "") + (datasource?.authSecret ?? "") + (datasource?.url ?? "") + (datasource?.name ?? "") + (datasource?.library ?? "") + (datasource?.site ?? ""));
        const str2 = JSON.stringify((originalDatasource?.authKey ?? "") + (originalDatasource?.authSecret ?? "") + (originalDatasource?.url ?? "") + (originalDatasource?.name ?? "") + (originalDatasource?.library ?? "") + (originalDatasource?.site ?? ""));

        return str1 === str2;
    }, [datasource, originalDatasource, authTokenChanged]);

    const isRequiredFieldMissing: boolean = React.useMemo(() => {
        return ((datasource?.type?.isUrlRequired && !datasource?.url)
            || (datasource?.type?.isKeyRequired && !datasource?.authKey)
            || (datasource?.type?.isSecretRequired && !datasource?.authSecret)
            || (datasource?.type?.isTokenRequired && !datasource?.authTokenData)
            || ((datasource?.type?.isKeyRequired || datasource?.type?.isUrlRequired || datasource?.type?.isSecretRequired) && !datasource?.name))
    }, [datasource]);

    const isConfirgureAuthDisabled: boolean = React.useMemo(() => {
        if (!datasource
            || (datasource && !datasource.name)
            || (datasource.type?.isUrlRequired && !datasource.url)
            || (datasource.type?.isKeyRequired && !datasource.authKey)
            || (datasource.type?.isSecretRequired && !datasource.authSecret)) {
            return true;
        }
        return false;
    }, [datasource]);

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                <DefaultButton styles={panelActionButtonStyle}
                    iconProps={{ iconName: "Cancel" }}
                    //disabled={isDoneDisabled}
                    onClick={() => {
                        handleCancel();
                    }}>
                    {languageStrings.Cancel}
                </DefaultButton>
                <PrimaryButton styles={panelActionButtonStyle}
                    iconProps={{ iconName: "Checkmark" }}
                    //disabled={isDoneDisabled}
                    onClick={async () => {
                        await handleDone();
                    }}>
                    {languageStrings.Done}
                </PrimaryButton>
            </Stack>
        ),
        [isDoneDisabled, addDataSourceFieldError],
    );

    return (
        <Panel
            headerText={originalDatasource?.name}
            isOpen={isOpen}
            onDismiss={() => {
                setDataSourceFieldNameError(false);
                setAddDataSourceFieldError("");
                setAddMode(false);
                dismissPanel();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <Stack horizontal horizontalAlign="space-between" style={{ marginBottom: 10, borderBottomColor: 'rgb(237, 235, 233)', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}>
                <Label>{languageStrings.EditDatasource}</Label>
                <InfoBox title={languageStrings.EditDatasource} content={languageStrings.EditDatasourceDescription} />
            </Stack>
            {(datasource?.type?.isKeyRequired || datasource?.type?.isUrlRequired || datasource?.type?.isSecretRequired) &&
                <Stack tokens={{ childrenGap: 15 }} style={{ marginBottom: 5, borderBottom: "rgb(237, 235, 233) 0.5px solid", paddingBottom: 20 }}>
                    <Label styles={sectionLabel}>
                        {languageStrings.DataSourceSettings}
                    </Label>
                    {datasource?.type?.id !== DatasourceTypeEnum.SharePoint && <TextField
                        label={languageStrings.FriendlyName}
                        value={datasource?.name}
                        onChange={(_, nv) => setDatasource({ ...datasource, name: nv })}
                    />}
                    {datasource?.type?.isUrlRequired &&
                        <TextField
                            label={datasource?.type?.id !== DatasourceTypeEnum.SharePoint ? languageStrings.Url : languageStrings.SiteUrl}
                            value={datasource?.url}
                            onChange={(_, nv) => setDatasource({ ...datasource, url: nv })}
                        />}
                    {datasource?.type?.isKeyRequired &&
                        <TextField
                            label={languageStrings.AuthKey}
                            value={datasource?.authKey}
                            type="password"
                            canRevealPassword
                            onChange={(_, nv) => setDatasource({ ...datasource, authKey: nv })}
                        />}
                    {datasource?.type?.isSecretRequired &&
                        <TextField
                            label={languageStrings.AuthSecret}
                            value={datasource?.authSecret}
                            type="password"
                            canRevealPassword
                            onChange={(_, nv) => setDatasource({ ...datasource, authSecret: nv })}
                        />}
                    {datasource?.type?.isTokenRequired &&
                        <Stack>
                            <Label>{languageStrings.AuthToken}</Label>
                            <div>
                                <DefaultButton
                                    onClick={async () => {
                                        try {
                                            const request: IDatasourceAuthDto = {
                                                name: datasource.name,
                                                tenantId: datasource.tenantId,
                                                typeId: datasource.typeId,
                                                typeName: dsTypes.find(t => t.id === datasource.typeId).name,
                                                credentials: {
                                                    url: datasource.url,
                                                    clientId: datasource.authKey,
                                                    clientSecret: datasource.authSecret,
                                                }
                                            }

                                            const windowName = 'SignInWindow';
                                            const windowFeatures = 'width=600,height=600,location=no,menubar=no,toolbar=no';
                                            const windowOrigin = window.location.origin.trimEnd();

                                            let authUrl: URL = new URL(`${windowOrigin.endsWith("/") ? windowOrigin.slice(0, -1) : windowOrigin}/auth`);
                                            authUrl.searchParams.append("ds", LZString.compressToEncodedURIComponent(JSON.stringify(request)));

                                            window.open(authUrl, windowName, windowFeatures);
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }}
                                    disabled={isConfirgureAuthDisabled}
                                >
                                    {languageStrings.Configure}
                                </DefaultButton>
                            </div>
                        </Stack>
                    }
                    {datasource?.type?.id === DatasourceTypeEnum.SharePoint && <TextField
                        label={languageStrings.ListName}
                        value={datasource?.name}
                        onChange={(_, nv) => setDatasource({ ...datasource, name: nv })}
                    />}
                    <TextField
                        label={languageStrings.RestrictToSites}
                        value={datasource?.site ? datasource?.site : ""}
                        onChange={(_, nv) => setDatasource({ ...datasource, site: nv })}
                    />
                    <TextField
                        label={languageStrings.RestrictToLibraries}
                        value={datasource?.library ? datasource?.library : ""}
                        onChange={(_, nv) => setDatasource({ ...datasource, library: nv })}
                    />
                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                        {datasource?.typeId === DatasourceTypeEnum.SharePoint && <Stack.Item grow>
                            <DefaultButton
                                iconProps={{ iconName: resetCacheIconName }}
                                onClick={async () => {
                                    await resetDatasourceCache(datasource);
                                    setResetCacheIconName("CheckMark");

                                    setTimeout(() => {
                                        setResetCacheIconName('Refresh');
                                    }, 3000);
                                }}
                                disabled={isRequiredFieldMissing}
                            >
                                {"Reset cache"}
                            </DefaultButton>
                        </Stack.Item>}
                        <DefaultButton
                            iconProps={{ iconName: "cancel" }}
                            onClick={() => {
                                setDatasource({ ...originalDatasource })
                            }}
                            disabled={isSaveDisabled}
                        >
                            {languageStrings.Cancel}
                        </DefaultButton>
                        <PrimaryButton
                            iconProps={{ iconName: "save" }}
                            onClick={async () => {
                                setAuthTokenChanged(false);
                                setOriginalDatasource(datasource);
                                await editDatasource(datasource);
                                datasourceUpdated(datasource);
                            }}
                            disabled={isSaveDisabled || isRequiredFieldMissing}
                        >
                            {languageStrings.Save}
                        </PrimaryButton>
                    </Stack>
                </Stack>}
            <Stack horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                {(datasource?.type?.isKeyRequired || datasource?.type?.isUrlRequired || datasource?.type?.isSecretRequired) &&
                    <Label styles={sectionLabel}>
                        {languageStrings.MappedFields}
                    </Label>}
                <CommandBarButton
                    iconProps={{ iconName: "add", styles: panelCommandButtonStyle }}
                    style={{ paddingTop: 5, paddingBottom: 5, }}
                    text={languageStrings.Add}
                    onClick={() => {
                        setAddMode(true);
                    }}
                />
            </Stack>
            {addMode && addDataSourceFieldError && <MessageBar messageBarType={MessageBarType.error}>{addDataSourceFieldError}</MessageBar>}
            {addMode &&
                <Stack tokens={{ childrenGap: 15 }}
                    style={{ marginBottom: 10, paddingBottom: 15, borderBottom: "rgb(237, 235, 233) 0.5px solid" }}
                >
                    {structure?.length > 0
                        ? <Dropdown
                            label={languageStrings.SelectDatasourceField}
                            options={datasourceFieldsOptions}
                            placeholder={languageStrings.SelectDatasourceField}
                            onChange={(_, option) => {
                                setNewBoundField({ ...newBoundField, datasourceField: option.text })
                            }}
                        />
                        : <TextField
                            label={languageStrings.DataSourceFieldName}
                            placeholder={languageStrings.DataSourceFieldName}
                            onChange={(_, nv) => setNewBoundField({ ...newBoundField, datasourceField: nv })}
                            errorMessage={dataSourceFieldNameError ? languageStrings.DataSourceFieldNameDuplicate : ""}
                        />}
                    <TextField
                        label={languageStrings.SharepointColumnName}
                        placeholder={languageStrings.EnterSharepointColumn}
                        onChange={(_, nv) => setNewBoundField({ ...newBoundField, name: nv })}
                    />
                    <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 15 }}>
                        <DefaultButton styles={panelActionButtonStyle}
                            iconProps={{ iconName: "Cancel" }}
                            onClick={() => {
                                setNewBoundField({ ...defaultBoundField });
                                setDataSourceFieldNameError(false);
                                setAddMode(false);
                                setAddDataSourceFieldError("");
                            }}>
                            {languageStrings.Cancel}
                        </DefaultButton>
                        <PrimaryButton styles={panelActionButtonStyle}
                            iconProps={{ iconName: "Checkmark" }}
                            disabled={isAddDisabled}
                            onClick={async () => {
                                await handleAdd();
                            }}>
                            {languageStrings.Add}
                        </PrimaryButton>
                    </Stack>
                </Stack>
            }
            <Stack tokens={{ childrenGap: 15 }}>
                {boundFields}
            </Stack>
        </Panel>
    )
}

const sectionLabel: Partial<ILabelStyles> = {
    root: {
        fontSize: FontSizes.mediumPlus
    }
}

export default connector(EditDatasourcePanel)